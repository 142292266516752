import React,{ useEffect }  from "react";
import "./WKM.css";
import MemberPhoto from "../../images/Member-of-WKM-logo.png"
import MemberCerti from "../../images/wkm-certificate.jpg"

const WKM = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="wkm-page">
      <div class="wkm-heading">Inspiring Towards Greater Kindness</div>
      <div class="wkm-subheading">
        Kindness Unlimited is honored to be a Member of The World Kindness
        Movement since 2005.
      </div>
      <div class="member-photo">
      <img src={MemberPhoto} class="image-1" alt="memPhoto"/>
      </div>
      <div class="wkm-logo"></div>
      <div class="wkm-body">
        Inspiring Towards Greater Kindness Kindness Unlimited is honored to be a
        Member of The World Kindness Movement since 2005. The mission of the
        World Kindness Movement is to inspire Individuals, Families, Academia,
        Corporates and Communities towards greater kindness and ultimately to
        connect nations to create a kinder world.
        <br /> <br />
        World Kindness INDIA received independent international endorsement as
        the Peak National Kindness Body by the World Kindness Movement.
        <br /> <br />
        The World Kindness Movement is the peak global body and international
        non-government organization with no religious or political affiliation.
        Its chief objective is to foster goodwill among the broad community –
        local, national and international – by way of kindness, and in so doing,
        create greater understanding and co-operation between all people and all
        nations throughout the world.
        <br /> <br />
        The idea behind the World Kindness Movement crystallized at a conference
        in Tokyo in 1997 when the Small Kindness Movement of Japan brought
        together like-minded kindness movements from around the world.
        <br /> <br />
        The World Kindness Movement was officially launched in Singapore on
        November 18, 2000. Today, the World Kindness Movement brings together a
        worldwide coalition of kindness organizations that study and promote
        improved individual and collective human behavior.
        <br /> <br />
        Current members include over 24 nations, with representatives from
        Australia, Bangladesh, Brazil, Canada, China, France, India, Indonesia,
        Italy, Japan, Mexico, Nepal, New Zealand, Nigeria, Oman, Pakistan,
        Romania, Scotland, South Africa, Switzerland, South Korea, Thailand,
        Uganda, Ukraine, United Arab Emirates, United Kingdom and the USA.
        <br /> <br />
        <div class="photo-container">
        <div class="member-certi">
        <img src={MemberCerti} class="image-1" alt="memCerti"/>
        </div></div>
        For more information, please visit our website{" "}
        <a href="https://www.theworldkindnessmovement.org/">
          The World Kindness Movement
        </a>
      </div>
    </div>
  );
};

export default WKM;
