import React, { useEffect }  from "react";
import "./DonateAToi.css";
import donate1 from "../../../images/donate1.jpg";
import donate2 from "../../../images/donate2.jpg";
import donate3 from "../../../images/donate3.jpg";
import donate4 from "../../../images/donate4.jpg";
import nirmala from "../../../images/Nirmala_mehendale_compressed.png";
import Toiing from "../../../images/sponsors/toiing.png";

const DonateAToi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="donate-a-toi">
      <div class="center-part col">
        <div class="donate-head row">Donate-A-Toi</div>
        <p class="text">
          3 Donate-a-Toi drives were conducted and it was heartwarming to see
          little ones being generous and sharing their pre-loved toys. Their
          kindness was also appreciated with a surprise letter from Santa and a
          gift from Toiing! The pre loved toys received at these drives are 250
          in number and will be presented to deserving kids throughout the year
          who will continue to love them. A big thank you to Toiing.
          <br />
          <br />
          KU is in collaboration with Toiing to collect and distribute 5000
          preloved toys in 2020.
        </p>
        <div class="row image-row">
          <img class="col-md-3 photo-dat" src={donate1} alt="hwgh" />
          <img class="col-md-3 photo-dat" src={donate2} alt="hwgh" />
          <img class="col-md-3 photo-dat" src={donate3} alt="hwgh" />
          <img class="col-md-3 photo-dat" src={donate4} alt="hwgh" />
        </div>

        <div class="nirmala">
          <div class="row">
            <div class="col-md-6">
              <div class="nirmala-person">
                <div class="nirmala-text">
                  <p>
                    Pre-loved toys in good condition are extra special, filled
                    with love and stories. KU is happy to support the
                    Donate-A-Toi initiative to help give toys new homes. The
                    project will help bring one of the most cherished aspects of
                    childhood come alive for so many kids.
                  </p>
                </div>
                <div class="nirmala-person-details">
                  <img class="nirmala-photo" src={nirmala} alt="person-1" />
                  <div class="nirmala-details">
                    <div class="nirmala-name">Nirmala Mehendale</div>
                    <div class="nirmala-place">
                      Founder Trustee and General Secretary
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 toiing-logo">
              <div class="donate-head">Event Partner</div>
              <a href="https://toiing.com/">
                <img src={Toiing} height="150" width="150" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="row">
        <div class="col-md-6 givers-recivers">
          <div class="donate-head">Givers</div>
          <hr />
          <ul class="school-names">
            <li>
              <a href="https://www.viverointernational.com/">
                Vivero International Pre School, Powai
              </a>
            </li>
            <li>
              <a href="http://www.lecolemontessori.com/">
                L’Ecole Montessori Pre School, Andheri West{" "}
              </a>
            </li>
            <li>
              <a href="http://kiddington.in/">
                Kiddington Play Zone, Kandivali{" "}
              </a>
            </li>
          </ul>
        </div>
          <div class="col-md-6 givers-recivers">
            <div class="donate-head">Recivers</div>
            <hr />
            <ul class="school-names">
              <li>
                <a href="http://www.navjeet.org/">

                Navjeet Community Center, Hill Road, Ranwar, Bandra West                </a>
              </li>
            </ul>
            </div>
        </div>
      </div>
    </section>
  );
};

export default DonateAToi;
