import styled from "styled-components";
import { Link } from "react-router-dom";


export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* margin-left: 1.5rem; */
`;


export const NavLinks = styled(Link)`
  color: #5c374c;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 400;
  height: 100%;
  cursor: pointer;
  &:hover {
   font-weight: 500;
   color: #000;
  }
  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    &:hover {
      color: #5c374c;
      transition: all 0.3s ease;
    }
  }

  @media screen and (max-width: 1024px) {
    text-align: center;
    padding: 2rem 0.3rem;
    width: 100%;
    display: table;
    &:hover {
      color: #5c374c;
      transition: all 0.3s ease;
    }
  }
`;
