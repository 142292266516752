/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './Footer.css';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="upper_footer">
<div class="row row-footer">
    <div class="col-lg-3 col-md-6 btm-footer-items">
    <div class="quick_links">

      <h3>Quick Links</h3>
      <Link to='/'>     <p>Home</p></Link>

      <Link to='/ourteam'><p>Our Team</p></Link>
      <Link to='/wwd'><p>About Us</p></Link>
      <Link to='/contactus'><p>Contact Us</p></Link>
      <Link to='/opportunities'><p>Volunteering Programme</p></Link>
      <Link to='/engagements'><p>KU Projects</p></Link>
      <Link to='/collaborations'><p>Collaborations</p></Link>
      <Link to='/privacypolicy'><p>Privacy Policy</p></Link>
    </div>
    </div>
    <div class="col-lg-3 col-md-6 btm-footer-items">
    <div class="kindness_footer">
      <h3>Kindness Unlimited</h3>
      <p>A-102, Ajanta Sant Muktabai Road, Vile Parle(E), Mumbai-400057.</p>
      <p>T: +91 9820710081</p>
      <p>E: contactus@kindnessunlimited.in</p>
      <h3> Contributions can be made to Account details </h3>
      <p>SB A/c 3736000107003253 </p>
      <p>IFSC code: PUNB0373600 .</p>
      <p>Punjab National Bank, Mumbai - 57</p>

    </div>

    </div>
    <div class="col-lg-3 col-md-6 btm-footer-items">

    <div class="regist_details">
      <h3>Trust Registration Details</h3>
      <p>Trust Registration No. – E22702 (Registered on July 01, 2005)</p>
      <p>12-A Registration No. – TR/39936 (Registered on April 18, 2006)</p>
      <p>80 G Exemption applicable of the IT Act</p>
    </div>
    </div>
    <div class="col-lg-3 col-md-6 google_maps">
      <iframe class="map-google img-fluid" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7540.52340470599!2d72.83877756977537!3d19.096171800000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9b27305a5fb%3A0xe7f934c4898cce19!2sKindness%20Unlimited!5e0!3m2!1sen!2sin!4v1654256686912!5m2!1sen!2sin" width="300" height="300" styles="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    </div>
</div>







    <div class="old_footer">
    <span>Stay connected for upcoming events, regular updates & interesting BTS at</span>
    <div class="icons">
      <a href="https://www.instagram.com/kindnessunlimited/"><i class="fa fa-instagram"></i></a>
      <a href="https://www.facebook.com/KindnessUnlimited.in/"><i class="fa fa-facebook"></i></a>
      <a href="https://www.linkedin.com/company/kindness-unlimited/"><i class="fa fa-linkedin"></i></a>
      <a href="https://www.youtube.com/user/KindnessUnlimited"><i class="fa fa-youtube"></i></a>
    </div>
    Copyright &copy; 2023 | All Rights Reserved
    </div>

  </footer>
  );
};

export default Footer;
