import React, { useEffect } from "react";
import "./Hangouts.css";
import Upstander from "../../../images/Upstander.jpg";

import { Link } from "react-router-dom";

const Hangouts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="hangouts">
        <div class="hangouts-heading">
          Kindness Hangouts
        </div>
        <div class="center-part">
          <section class="accommodation">
            <div class="stay">
              <Link to="././upstander" class="stay-content">
                <img src={Upstander} alt="Image2" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Upstander - The Power of One
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Hangouts;
