import React from "react";
import "./Donate.css";
import ScriptTag from "react-script-tag";

const Donate = () => {
  return (
    <>
      <ScriptTag
        src="https://danamojo.org/dm/js/widget.js"
        type="text/javascript"
      />
      {() => {
        if (
          document.getElementById("ngoContentContainer").innerHTML.length < 40
        ) {
          document.getElementById("ngoContentContainer").innerHTML =
            "<center> <p style='color:#a94442;'>we are sorry that our systems are down. we will be up shortly. apologies for the inconvenience.</p></center>";
        }
      }}
      
      <div id="dmScriptContainer" style={{ display: "none" }}>
        <a href="#">Donate Now</a>
      </div>
      <div
        id="ngoContentContainer"
        iNGOId="932"
        oLayout="GRID"
        oDisplay="product"
        oDisplayTab="once,monthly"
        class="donate-page"
      >
        <center>
          <img
            alt="please wait..."
            src="https://danamojo.org/dm/css/images/loading.gif"
          />
        </center>
      </div>
    </>
  );
};

export default Donate;