import React from "react";
import "./Volunteering.css";
import Rasika from "../../../images/RASIKABANSOD.jpg";
import Gurpreet from "../../../images/Gurpreet.jpg";

import socialMedia from "../../../docs/KU-Social-Opportunities.pdf";

const Volunteering = () => {
  return (
    <section class="volunteering">
      <h3 class="heading-volunteering">Volunteering</h3>
      <h5 class="sub-heading-volunteering">
        Opportunities (Click on a domain for details)
      </h5>

      <div class="box-volunteering">
        <div class="volunt-box">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">
            <div class="volunt-name">Social Media</div>
            <div class="volunt-sub">
              {" "}
              Content Writing, Graphic designing & Engagement
            </div>
          </a>
        </div>

        <div class="volunt-box">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">
            <div class="volunt-name">Website </div>
            <div class="volunt-sub"> Development, Design & Management</div>
          </a>
        </div>
        <div class="volunt-box">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">
            <div class="volunt-name"> Organisation Building</div>
            <div class="volunt-sub"> Operations, Planning & Management</div>
          </a>
        </div>
        <div class="volunt-box">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">
            <div class="volunt-name">Fundraising</div>
            <div class="volunt-sub"> Grants, Crowdfunding & Corpus Funds</div>
          </a>
        </div>
        <div class="volunt-box">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">
            <div class="volunt-name"> Communications</div>
            <div class="volunt-sub">News & Media</div>
          </a>
        </div>
        <div class="volunt-box">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">
            <div class="volunt-name">Relationship building</div>
            <div class="volunt-sub">
              {" "}
              Individuals, Corporations & High Net Worth Individual
            </div>
          </a>
        </div>
      </div>
      <h5 class="sub-heading-volunteering">Testimonals</h5>
      <div class="feedback-vlt">
        <div class="feedback-person-vlt">
          <div class="feedback-text-vlt">
            <p>
              It's a great way to give back to your local community and the
              world or make a difference to the people around them. One of the
              best things about volunteering is meeting the other volunteers and
              our SPOC.
            </p>
          </div>
          <div class="feedback-person-details-vlt">
            <img  class="testimonial-img" src={Rasika} alt="person-1" />
            <div class="user-details-vlt">
              <div class="user-name-vlt">Rasika Bansod</div>
              <div class="user-place-vlt">Volunteer, 1MillionStory Campaign</div>
            </div>
          </div>
        </div>

        <div class="feedback-person-vlt">
          <div class="feedback-text-vlt">
            <p>
              Volunteering at KU helped me realise that perhaps kindness isn’t
              rare; but in fact, is everywhere. The need of the hour is to be
              observant & kind. Today’s youth must come forward to join KU in
              this beautiful movement- the kindness movement.
            </p>
          </div>
          <div class="feedback-person-details-vlt">
            <img  class="testimonial-img" src={Gurpreet} alt="person-1" />
            <div class="user-details-vlt">
              <div class="user-name-vlt">Gurpreet Kaur N</div>
              <div class="user-place-vlt"> Volunteer, 1MillionStory Campaign</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Volunteering;
