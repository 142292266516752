import React, { useEffect }  from "react";
import "./KindnessCircles.css";
import KC1 from "../../../images/KC1.jpg";
import KC2 from "../../../images/KC2.jpg";
import KC3 from "../../../images/KC3.jpg";
import KC4 from "../../../images/KC4.jpg";
import KC5 from "../../../images/KC5.jpg";
import School1 from "../../../images/schools1.jpg";
import School2 from "../../../images/schools2.jpg";
import School3 from "../../../images/schools3.jpg";
import School4 from "../../../images/schools4.jpg";
import School5 from "../../../images/schools5.jpg";
import School6 from "../../../images/schools6.jpg";
import School7 from "../../../images/schools7.jpg";
import School8 from "../../../images/schools8.jpg";
import School9 from "../../../images/schools9.jpg";

const KindnessCircles = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="circles">
      <div class="col">
        <div class="row circles-heading">Kindness Circles</div>
        <div class="row why-kindness row">
          <div class="row why-circle-heading">Why Kindness circle?</div>
          <div class="col col-md-8 row circles-content">
            Kindness is fundamental to human existence, the core attribute of
            human beings. Kindness increases our ability to form meaningful
            connections with others too. And when you are kind it does come
            back, as Kindness Unlimited(KU) defines Kindness… it is balancing
            Self Interest with Common Interest. Hence, KU believes it is
            important to inculcate & nurture the attribute of Kindness since
            childhood… inception of one’s very existence, therefore…. KINDNESS
            CIRCLE.
          </div>
          <div class="col-md-4 photo-container">
            <img class="photo-kc" src={KC1} alt="hwgh" />
          </div>
        </div>
        <div class="row why-kindness row">
          <div class="row why-circle-heading">OBJECTIVE</div>
          <div class="col-md-4 photo-container">
            <img class="photo-objective" src={KC2} alt="hwgh" />
          </div>
          <div class="col-md-8 col row circles-content">
            <ul>
              <li>
                The Butterfly Effect in Chaos Theory asserts that a tiny event
                in one region of the globe can have a substantial effect
                somewhere else. Incorporate the smallest acts of kindness into
                your everyday life and notice the ripple effects.
              </li>
              <li>
                Children and adolescents do not learn kindness by only thinking
                about it and talking about it. Kindness is best learned by
                feeling it so that they can reproduce it. And Kindness is not
                something that demands hard work. It originates from the simple
                act of doing no harm to others.
              </li>
              <li>
                It influences the giver more than the receiver and has
                correlations with enhanced mental, emotional and physical
                well-being.
              </li>
              <li>
                It’s become quite clear that modern education must encompass
                more than just academics, that in order for children to develop
                into happy, confident, well-rounded individuals, matters of the
                heart must be taken seriously and nurtured as a matter of
                priority
              </li>
              <li>
                The random acts of kindness create a warm and supportive
                environment that will encourage students to help and support
                each other instead of bullying the weaker ones.
              </li>
              <li>
                Kind acts stimulate the production of the hormone serotonin, and
                children tend to become calmer and more focused, allowing them
                to concentrate better on their studies.
              </li>
              <li>
                When children are not exposed to undue stress, their health and
                mood improve, resulting in smarter and happier students.
              </li>
              <li>
                When children are part of projects that help others less
                fortunate than themselves, it provides them with a real sense of
                perspective and helps them appreciate the good things in their
                own lives.
              </li>
            </ul>
          </div>
        </div>
        <div class="row why-kindness row">
          <div class="row why-circle-heading">APPROACH</div>
          <div class="col-md-8 col row circles-content">
            <ul>
              <li>
                The Kindness circle is a programme to nurture kindness within
                school going students with small activities performed on a
                monthly basis across every class facilitated by the class
                teacher. Schools could follow any preferred medium to initiate
                the kindness circle
                <ol>
                  <li>Include this as part of the Moral science class</li>
                  <li>Include a weekly class for kindness circle</li>
                  <li>
                    Daily sharing of kindness circle in the school assembly
                  </li>
                  <li>As per the convenience of schools</li>
                </ol>
              </li>
              <li>
                Programme contains ‘Kindness Kit’ with different activities
                listed (It could be also done virtually with students assigned
                activities randomly) every student in the classroom is expected
                to pick 1 activity & perform it within the next 7 days or there
                could be a group of 5 or 6 (this may also create little fun ).
              </li>
              <li>
                The resource includes a series of kind acts on each of the
                following aspects of Kindness; Courage, Compassion, Integrity,
                Self-Awareness, Respect, Generosity, Gratitude, Inclusion,
                Optimism and Humour.
              </li>
              <li>
                The class teacher is expected to monitor & nudge their students
                through periodic reminders & discuss the experience of students
                during a scheduled kindness circle.
              </li>
              <li>
                The kind kits are not intended to be used in any particular
                order nor is it necessary to present all within a particular
                year or grade. Our intent was to develop a resource that would
                offer the most flexibility to teachers and schools as we try to
                nurture the development of social responsibility within our
                students. The variety of kind acts allows teachers or schools to
                find some alignment between a school goal, initiative, a student
                leadership program, or an identified need within a school,
                class, or grade level.
              </li>
            </ul>
          </div>
          <div class="col-md-4 photo-container">
            <img class="photo-approach" src={KC3} alt="hwgh" />
            <img class="photo-approach" src={KC4} alt="hwgh" />
          </div>
        </div>
        <div class="row why-kindness kindness-example row">
          <div class="row why-circle-heading">
            Some examples of kindness activity-
          </div>
          <div class="col-md-3 photo-container">
            <img class="photo-example" src={KC5} alt="hwgh" />
          </div>

          <div class="col-md-9 col row circles-content">
            <ol>
              <li>
                Give water & snacks to food/e-commerce delivery /stranger who
                have come to your home to deliver anything. Don’t forget to tell
                him that he/she is doing an amazing job & smile ☺
              </li>
              <li>
                Put positive sticky notes in places where others will see them
                and be uplifted. It could be your house/apartment complex/club
                house/school or any place where you think people glance
              </li>
              <li>
                Pick up trash that you see in your complex/ road/ house &
                dispose it in the dustbin
              </li>
              <li>
                Water 5 plants in your house/neighbourhood & share all your love
                to these trees
              </li>
              <li>
                Play /Talk indoor/outdoor with the son/daughter of your security
                Guard. It could be any game virtually or in person
              </li>
            </ol>
          </div>
        </div>

        <div class="row why-kindness circles-content">
          We welcome schools to fill this kindness sheet with your suggestions &
          also keep referring it for newer ideas. You could go through this
          detailed presentation for any query about the kindness circle. And If
          you want to know more about anything, write to
          vineet@kindnessunlimited.in
          <br />
          <br />
          We intend to kickstart the programme on DaanUtsav (Joy of Giving
          Week), India’s own festival of giving on 2nd October & would be
          forming a WhatsApp group to scale the madness.
        </div>

        <div class="collab-subheading">Participating Schools</div>
        <div class="row parti-schools">
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School1} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School2} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School3} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School4} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School5} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School6} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School7} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School8} height="150" width="150" alt="" />
          </div>
          <div class="col-lg-3 col-md-4 col-xs-6 partner-logos">
            <img src={School9} height="150" width="150" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KindnessCircles;
