import React, { useEffect }  from "react";
import "./Musical.css";
import musical1 from "../../../images/musical1.jpg";
import musical2 from "../../../images/musical2.jpg";
import musical3 from "../../../images/musical3.jpg";

const Musical = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="musical">
      <div class="center-part">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
        Musical for Fund-raising
        </div>

  
        
            <p class="text">
              On 21st December 2019, A Bollywood Musical by singers was
              organised to raise funds for a greater kind cause – A Kindness
              Unlimited sponsored Wheelchair match in February 2020. Thanks to
              Musical Harmony Group for a very memorable evening that was
              fulfilled with incredible songs and music by the talented team of
              singers. The Joy was doubled as they helped us raise funds for the
              cause of Maharashtra Wheel Chair Cricket. RJ Pari Verma rocked the
              house with her Kindness and Nisha Gupta & the cricket team
              inspired all the people present with their positive vibes and
              abilities. A perfect KUL Evening!
            </p>
         
        
        <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={musical1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={musical2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={musical3} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={musical1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={musical2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={musical3} />
          </button>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Musical;
