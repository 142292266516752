import React from "react";
import "./Gallery.css";

import nashta1 from "../../images/nashta1.jpg";
import nashta2 from "../../images/nashta2.jpg";
import nashta3 from "../../images/nashta3.jpg";
import nashta4 from "../../images/nashta4.jpg";
import nashta5 from "../../images/nashta5.jpg";
import nashta6 from "../../images/nashta6.jpg";
import nashta7 from "../../images/nashta7.jpg";
import nashta8 from "../../images/nashta8.jpg";
import nashta9 from "../../images/nashta9.jpg";
import nashta10 from "../../images/nashta10.jpg";
import nashta11 from "../../images/nashta11.jpg";
import nashta12 from "../../images/nashta12.jpg";
import nashta13 from "../../images/nashta13.jpg";
import nashta14 from "../../images/nashta14.jpg";
import nashta15 from "../../images/nashta15.jpg";
import nashta16 from "../../images/nashta16.jpg";
import nashta17 from "../../images/nashta17.jpg";
import nashta18 from "../../images/nashta18.jpg";
import nashta19 from "../../images/nashta19.jpg";
import nashta20 from "../../images/nashta20.jpg";
import dance1 from "../../images/dance-kindness1.jpg";
import dance2 from "../../images/dance-kindness2.jpg";
import dance3 from "../../images/dance-kindness3.jpg";
import dance4 from "../../images/dance-kindness4.jpg";
import dance5 from "../../images/dance-kindness5.jpg";
import dance6 from "../../images/dance-kindness6.jpg";
import dance7 from "../../images/dance-kindness7.jpg";
import dance8 from "../../images/dance-kindness8.jpg";
import dance9 from "../../images/dance-kindness9.jpg";
import dance10 from "../../images/dance-kindness10.jpg";
import dance11 from "../../images/dance-kindness11.jpg";
import dance12 from "../../images/dance-kindness12.jpg";
import dance13 from "../../images/dance-kindness13.jpg";
import Kohinoor1 from "../../images/KBS1.jpg";
import Kohinoor2 from "../../images/KBS2.jpg";
import Kohinoor3 from "../../images/KBS3.jpg";
import Kohinoor4 from "../../images/KBS4.jpg";
import Kohinoor5 from "../../images/KBS5.jpg";
import Kohinoor6 from "../../images/KBS6.jpg";
import Kohinoor7 from "../../images/KBS7.jpg";
import Kohinoor8 from "../../images/KBS8.jpg";
import Kohinoor9 from "../../images/KBS9.jpg";
import Kohinoor10 from "../../images/KBS10.jpg";
import nspa1 from "../../images/nspa1.jpg";
import nspa2 from "../../images/nspa2.jpg";
import nspa3 from "../../images/nspa3.jpg";
import nspa4 from "../../images/nspa4.jpg";
import OAH1 from "../../images/OAH1.jpg";
import OAH2 from "../../images/OAH2.jpg";
import OAH3 from "../../images/OAH3.jpg";
import OAH4 from "../../images/OAH4.jpg";
import OAH5 from "../../images/OAH5.jpg";
import OAH6 from "../../images/OAH6.jpg";
import kcevent1 from "../../images/kcevent1.jpg";
import kcevent2 from "../../images/kcevent2.jpg";
import kcevent3 from "../../images/kcevent3.jpg";
import kcevent4 from "../../images/kcevent4.jpg";
import kcevent5 from "../../images/kcevent5.jpg";
import kcevent6 from "../../images/kcevent6.jpg";
import DAN1 from "../../images/DAN1.jpg";
import DAN2 from "../../images/DAN2.jpg";
import DAN3 from "../../images/DAN3.jpg";
import DAN4 from "../../images/DAN4.jpg";
import DAN5 from "../../images/DAN5.jpg";
import DAN6 from "../../images/DAN6.jpg";
import beats1 from "../../images/beats1.jpg";
import beats2 from "../../images/beats2.jpg";
import beats3 from "../../images/beats3.jpg";
import beats4 from "../../images/beats4.jpg";
import beats5 from "../../images/beats5.jpg";
import beats6 from "../../images/beats6.jpg";
import beats7 from "../../images/beats7.jpg";
import beats8 from "../../images/beats8.jpg";
import beats9 from "../../images/beats9.jpg";
import beats10 from "../../images/beats10.jpg";
import beats11 from "../../images/beats11.jpg";
import beats12 from "../../images/beats12.jpg";
import beats13 from "../../images/beats13.jpg";
import beats14 from "../../images/beats14.jpg";
import FD1 from "../../images/FD1.jpg";
import FD2 from "../../images/FD2.jpg";
import FD3 from "../../images/FD3.jpg";
import FD4 from "../../images/FD4.jpg";
import kj1 from "../../images/kj1.jpg";
import kj2 from "../../images/kj2.jpg";
import kj3 from "../../images/kj3.jpg";
import kj4 from "../../images/kj4.jpg";
import kj5 from "../../images/kj5.jpg";
import kj6 from "../../images/kj6.jpg";
import kj7 from "../../images/kj7.jpg";
import kj8 from "../../images/kj8.jpg";
import kj9 from "../../images/kj9.jpg";
import kj10 from "../../images/kj10.jpg";
import CSP1 from "../../images/CSP1.jpg";
import CSP2 from "../../images/CSP2.jpg";
import CSP3 from "../../images/CSP3.jpg";
import CSP4 from "../../images/CSP4.jpg";
import Zumba1 from "../../images/zumba1.jpg";
import Zumba2 from "../../images/zumba2.jpg";
import Zumba3 from "../../images/zumba3.jpg";
import Zumba4 from "../../images/zumba4.jpg";
import Zumba5 from "../../images/zumba5.jpg";
import Zumba6 from "../../images/zumba6.jpg";
import musical1 from "../../images/musical1.jpg";
import musical2 from "../../images/musical2.jpg";
import musical3 from "../../images/musical3.jpg";
import upstander1 from "../../images/upstander1.jpg";
import upstander2 from "../../images/upstander2.jpg";
import upstander3 from "../../images/upstander3.jpg";
import upstander4 from "../../images/upstander4.jpg";
import upstander5 from "../../images/upstander5.jpg";
import donate1 from "../../images/donate5.jpg";
import donate2 from "../../images/donate2.jpg";
import donate3 from "../../images/donate3.jpg";
import donate4 from "../../images/donate4.jpg";
import hangouts1 from "../../images/hangouts1.jpg";
import hangouts2 from "../../images/hangouts2.jpg";
import hangouts3 from "../../images/hangouts3.jpg";
import hangouts4 from "../../images/hangouts4.jpg";
import hangouts5 from "../../images/hangouts5.jpg";
import hangouts6 from "../../images/hangouts6.jpg";
import hangouts7 from "../../images/hangouts7.jpg";
import hangouts8 from "../../images/hangouts8.jpg";
import hangouts9 from "../../images/hangouts9.jpg";
import hangouts10 from "../../images/hangouts10.jpg";
import hangouts11 from "../../images/hangouts11.jpg";
import circles1 from "../../images/circles1.jpg";
import circles2 from "../../images/circles2.jpg";
import circles3 from "../../images/circles3.jpg";
import circles4 from "../../images/circles4.jpg";
import circles5 from "../../images/circles5.jpg";
import circles6 from "../../images/circles6.jpg";
import plantKU1 from "../../images/plantkindess1.jpeg";
import plantKU2 from "../../images/plantkindess2.jpeg";
import plantKU3 from "../../images/plantkindess3.jpeg";
import plantKU4 from "../../images/plantkindess4.jpeg";
import plantKU5 from "../../images/plantkindess5.jpeg";
import plantKU6 from "../../images/plantkindess6.jpeg";
import plantKU7 from "../../images/plantkindess7.jpeg";
import plantKU8 from "../../images/plantkindess8.jpeg";




const Gallery = () => {
  return (
    <div class="gallery">
      <div class="content-a cont-head gallery-heading" style={{ paddingLeft: "19px" }}>
        Gallery
      </div>
      <div class="plantKU">
    <div class="col">
      <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
        Plant Kindness
      </div>

      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions00"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={plantKU1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={plantKU2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={plantKU3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={plantKU4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={plantKU5} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={plantKU6} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={plantKU7} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={plantKU8} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions00"
          type="button"
        >
          <span
            aria-hidden="true"
            class="carousel-control-prev-icon"
          ></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions00"
          type="button"
        >
          <span
            aria-hidden="true"
            class="carousel-control-next-icon"
          ></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions00"
            type="button"
          >
            <img class="img-thumb img-fluid" src={plantKU8} />
          </button>
        </div>
      </div>
    </div>
  </div>
      <div class="nashta">
        <div class="col">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Project Nashta
          </div>

          <div
            class="carousel slide"
            data-bs-ride="carousel"
            id="carouselExampleCaptions"
          >
            <div class="carousel-inner">
              <div class="carousel-item active bg-1">
                <div class="img-container-carousel d-flex">
                  <img class="img-main" src={nashta1} />
                </div>
              </div>
              <div class="carousel-item bg-2">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta2} />
                </div>
              </div>
              <div class="carousel-item bg-3">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta3} />
                </div>
              </div>
              <div class="carousel-item bg-4">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta4} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta5} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta6} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta7} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta8} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta9} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta10} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta11} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta12} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta13} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta14} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta15} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta16} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta17} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta18} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta19} />
                </div>
              </div>
              <div class="carousel-item bg-5">
                <div class="img-container-carousel d-flex">
                  <img class="img-main img-fluid" src={nashta20} />
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              data-bs-slide="prev"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <span
                aria-hidden="true"
                class="carousel-control-prev-icon"
              ></span>{" "}
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              data-bs-slide="next"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <span
                aria-hidden="true"
                class="carousel-control-next-icon"
              ></span>{" "}
              <span class="visually-hidden">Next</span>
            </button>

            <div class="carousel-indicators">
              <button
                aria-label="Slide 1"
                class="active"
                data-bs-slide-to="0"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta1} />
              </button>{" "}
              <button
                aria-label="Slide 2"
                data-bs-slide-to="1"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta2} />
              </button>{" "}
              <button
                aria-label="Slide 3"
                data-bs-slide-to="2"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta3} />
              </button>
              <button
                aria-label="Slide 4"
                data-bs-slide-to="3"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta4} />
              </button>
              <button
                aria-label="Slide 5"
                data-bs-slide-to="4"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta5} />
              </button>
              <button
                aria-label="Slide 6"
                data-bs-slide-to="5"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta6} />
              </button>
              <button
                aria-label="Slide 7"
                data-bs-slide-to="6"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta7} />
              </button>
              <button
                aria-label="Slide 8"
                data-bs-slide-to="7"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta8} />
              </button>
              <button
                aria-label="Slide 9"
                data-bs-slide-to="8"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta9} />
              </button>
              <button
                aria-label="Slide 10"
                data-bs-slide-to="9"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta10} />
              </button>
              <button
                aria-label="Slide 11"
                data-bs-slide-to="10"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta11} />
              </button>
              <button
                aria-label="Slide 12"
                data-bs-slide-to="11"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta12} />
              </button>
              <button
                aria-label="Slide 13"
                data-bs-slide-to="12"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta13} />
              </button>
              <button
                aria-label="Slide 14"
                data-bs-slide-to="13"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta14} />
              </button>
              <button
                aria-label="Slide 15"
                data-bs-slide-to="14"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta15} />
              </button>
              <button
                aria-label="Slide 16"
                data-bs-slide-to="15"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta16} />
              </button>
              <button
                aria-label="Slide 17"
                data-bs-slide-to="16"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta17} />
              </button>
              <button
                aria-label="Slide 18"
                data-bs-slide-to="17"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta18} />
              </button>
              <button
                aria-label="Slide 19"
                data-bs-slide-to="18"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta19} />
              </button>
              <button
                aria-label="Slide 20"
                data-bs-slide-to="19"
                data-bs-target="#carouselExampleCaptions"
                type="button"
              >
                <img class="img-thumb img-fluid" src={nashta20} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Dance for Kindness
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions1"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={dance1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance6} />
            </div>
          </div>
          <div class="carousel-item bg-7">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance7} />
            </div>
          </div>
          <div class="carousel-item bg-8">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance8} />
            </div>
          </div>
          <div class="carousel-item bg-9">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance9} />
            </div>
          </div>
          <div class="carousel-item bg-10">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance10} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance11} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance12} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance13} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions1"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions1"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance8} />
          </button>
          <button
            aria-label="Slide 9"
            data-bs-slide-to="8"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance9} />
          </button>
          <button
            aria-label="Slide 10"
            data-bs-slide-to="9"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance10} />
          </button>
          <button
            aria-label="Slide 11"
            data-bs-slide-to="10"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance11} />
          </button>
          <button
            aria-label="Slide 12"
            data-bs-slide-to="11"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance12} />
          </button>
          <button
            aria-label="Slide 13"
            data-bs-slide-to="12"
            data-bs-target="#carouselExampleCaptions1"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance13} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Kohinoor Business School
          </div>
        </div>
      </div>

      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions2"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={Kohinoor1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor6} />
            </div>
          </div>
          <div class="carousel-item bg-7">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor7} />
            </div>
          </div>
          <div class="carousel-item bg-8">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor8} />
            </div>
          </div>
          <div class="carousel-item bg-9">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor9} />
            </div>
          </div>
          <div class="carousel-item bg-10">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor10} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions2"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions2"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor8} />
          </button>
          <button
            aria-label="Slide 9"
            data-bs-slide-to="8"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor9} />
          </button>
          <button
            aria-label="Slide 10"
            data-bs-slide-to="9"
            data-bs-target="#carouselExampleCaptions2"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor10} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            NSPA Event
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions3"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={nspa1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={nspa2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={nspa3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={nspa4} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions3"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions3"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions3"
            type="button"
          >
            <img class="img-thumb img-fluid" src={nspa1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions3"
            type="button"
          >
            <img class="img-thumb img-fluid" src={nspa2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions3"
            type="button"
          >
            <img class="img-thumb img-fluid" src={nspa3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions3"
            type="button"
          >
            <img class="img-thumb img-fluid" src={nspa4} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Old Age Home
          </div>
        </div>
      </div>

      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions4"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={OAH1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={OAH2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={OAH3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={OAH4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={OAH5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={OAH6} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions4"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions4"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions4"
            type="button"
          >
            <img class="img-thumb img-fluid" src={OAH1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions4"
            type="button"
          >
            <img class="img-thumb img-fluid" src={OAH2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions4"
            type="button"
          >
            <img class="img-thumb img-fluid" src={OAH3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions4"
            type="button"
          >
            <img class="img-thumb img-fluid" src={OAH4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions4"
            type="button"
          >
            <img class="img-thumb img-fluid" src={OAH5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions4"
            type="button"
          >
            <img class="img-thumb img-fluid" src={OAH6} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            KC College Event
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions5"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={kcevent1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent6} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions5"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions5"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions5"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions5"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions5"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions5"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions5"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions5"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent6} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            One Day of Change – DAN
          </div>
        </div>
      </div>

      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions6"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={DAN1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={DAN2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={DAN3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={DAN4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={DAN5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={DAN6} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions6"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions6"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions6"
            type="button"
          >
            <img class="img-thumb img-fluid" src={DAN1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions6"
            type="button"
          >
            <img class="img-thumb img-fluid" src={DAN2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions6"
            type="button"
          >
            <img class="img-thumb img-fluid" src={DAN3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions6"
            type="button"
          >
            <img class="img-thumb img-fluid" src={DAN4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions6"
            type="button"
          >
            <img class="img-thumb img-fluid" src={DAN5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions6"
            type="button"
          >
            <img class="img-thumb img-fluid" src={DAN6} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Beats of a Kind
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions7"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={beats1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats5} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats6} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats7} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats8} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats9} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats10} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats11} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats12} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats13} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={beats14} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions7"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions7"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats8} />
          </button>
          <button
            aria-label="Slide 9"
            data-bs-slide-to="8"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats9} />
          </button>
          <button
            aria-label="Slide 10"
            data-bs-slide-to="9"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats10} />
          </button>
          <button
            aria-label="Slide 11"
            data-bs-slide-to="10"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats11} />
          </button>
          <button
            aria-label="Slide 12"
            data-bs-slide-to="11"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats12} />
          </button>
          <button
            aria-label="Slide 13"
            data-bs-slide-to="12"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats13} />
          </button>
          <button
            aria-label="Slide 14"
            data-bs-slide-to="13"
            data-bs-target="#carouselExampleCaptions7"
            type="button"
          >
            <img class="img-thumb img-fluid" src={beats14} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Food Distribution
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions8"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={FD1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={FD2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={FD3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={FD4} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions8"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions8"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions8"
            type="button"
          >
            <img class="img-thumb img-fluid" src={FD1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions8"
            type="button"
          >
            <img class="img-thumb img-fluid" src={FD2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions8"
            type="button"
          >
            <img class="img-thumb img-fluid" src={FD3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions8"
            type="button"
          >
            <img class="img-thumb img-fluid" src={FD4} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Kindness Jam
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions9"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={kj1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj6} />
            </div>
          </div>
          <div class="carousel-item bg-7">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj7} />
            </div>
          </div>
          <div class="carousel-item bg-8">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj8} />
            </div>
          </div>
          <div class="carousel-item bg-9">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj9} />
            </div>
          </div>
          <div class="carousel-item bg-10">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj10} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions9"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions9"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj8} />
          </button>
          <button
            aria-label="Slide 9"
            data-bs-slide-to="8"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj9} />
          </button>
          <button
            aria-label="Slide 10"
            data-bs-slide-to="9"
            data-bs-target="#carouselExampleCaptions9"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj10} />
          </button>
        </div>
      </div>
      <div class="dance-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Christmas Street Party
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions10"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={CSP1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={CSP2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={CSP3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={CSP4} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions10"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions10"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions10"
            type="button"
          >
            <img class="img-thumb img-fluid" src={CSP1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions10"
            type="button"
          >
            <img class="img-thumb img-fluid" src={CSP2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions10"
            type="button"
          >
            <img class="img-thumb img-fluid" src={CSP3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions10"
            type="button"
          >
            <img class="img-thumb img-fluid" src={CSP4} />
          </button>
        </div>
      </div>
      <div class="Zumba-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Zumba with Nina Foundation
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions11"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={Zumba1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba6} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions11"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions11"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions11"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions11"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions11"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions11"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions11"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions11"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba6} />
          </button>
        </div>
      </div>
      <div class="Zumba-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Bollywood Musical
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions12"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={musical1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={musical2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={musical3} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions12"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions12"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions12"
            type="button"
          >
            <img class="img-thumb img-fluid" src={musical1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions12"
            type="button"
          >
            <img class="img-thumb img-fluid" src={musical2} />
          </button>
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions12"
            type="button"
          >
            <img class="img-thumb img-fluid" src={musical3} />
          </button>
        </div>
      </div>
      <div class="upstander-kindness">
        <div class="center-part">
          <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
            Upstander – The Power of One
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions13"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={upstander1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={upstander2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={upstander3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={upstander4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={upstander5} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions13"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions13"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions13"
            type="button"
          >
            <img class="img-thumb img-fluid" src={upstander1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions13"
            type="button"
          >
            <img class="img-thumb img-fluid" src={upstander2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions13"
            type="button"
          >
            <img class="img-thumb img-fluid" src={upstander3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions13"
            type="button"
          >
            <img class="img-thumb img-fluid" src={upstander4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions13"
            type="button"
          >
            <img class="img-thumb img-fluid" src={upstander5} />
          </button>
        </div>
      </div>
      <div class="donate-kindness">
    <div class="center-part">
      <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
        Donate-A-Toi
      </div>
    </div>
  </div>
  <div
    class="carousel slide"
    data-bs-ride="carousel"
    id="carouselExampleCaptions14"
  >
    <div class="carousel-inner">
      <div class="carousel-item active bg-1">
        <div class="img-container-carousel d-flex">
          <img class="img-main" src={donate1} />
        </div>
      </div>
      <div class="carousel-item bg-2">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={donate2} />
        </div>
      </div>
      <div class="carousel-item bg-3">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={donate3} />
        </div>
      </div>
      <div class="carousel-item bg-4">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={donate4} />
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      data-bs-slide="prev"
      data-bs-target="#carouselExampleCaptions14"
      type="button"
    >
      <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      data-bs-slide="next"
      data-bs-target="#carouselExampleCaptions14"
      type="button"
      
    >
      <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
      <span class="visually-hidden">Next</span>
    </button>

    <div class="carousel-indicators">
      <button
        aria-label="Slide 1"
        class="active"
        data-bs-slide-to="0"
        data-bs-target="#carouselExampleCaptions14"
        type="button"
      >
        <img class="img-thumb img-fluid" src={donate1} />
      </button>{" "}
      <button
        aria-label="Slide 2"
        data-bs-slide-to="1"
        data-bs-target="#carouselExampleCaptions14"
        type="button"
      >
        <img class="img-thumb img-fluid" src={donate2} />
      </button>{" "}
      <button
        aria-label="Slide 3"
        data-bs-slide-to="2"
        data-bs-target="#carouselExampleCaptions14"
        type="button"
      >
        <img class="img-thumb img-fluid" src={donate3} />
      </button>
      <button
        aria-label="Slide 4"
        data-bs-slide-to="3"
        data-bs-target="#carouselExampleCaptions14"
        type="button"
      >
        <img class="img-thumb img-fluid" src={donate4} />
      </button>
    </div>
  </div>
  <div class="hangouts-kindness">
    <div class="center-part">
      <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
        Kindness Hangouts
      </div>
    </div>
  </div>
  <div
    class="carousel slide"
    data-bs-ride="carousel"
    id="carouselExampleCaptions15"
  >
    <div class="carousel-inner">
      <div class="carousel-item active bg-1">
        <div class="img-container-carousel d-flex">
          <img class="img-main" src={hangouts1} />
        </div>
      </div>
      <div class="carousel-item bg-2">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts2} />
        </div>
      </div>
      <div class="carousel-item bg-3">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts3} />
        </div>
      </div>
      <div class="carousel-item bg-4">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts4} />
        </div>
      </div>
      <div class="carousel-item bg-5">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts5} />
        </div>
      </div>
      <div class="carousel-item bg-6">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts6} />
        </div>
      </div>
      <div class="carousel-item bg-7">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts7} />
        </div>
      </div>
      <div class="carousel-item bg-8">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts8} />
        </div>
      </div>
      <div class="carousel-item bg-9">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts9} />
        </div>
      </div>
      <div class="carousel-item bg-10">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts10} />
        </div>
      </div>
      <div class="carousel-item bg-10">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={hangouts11} />
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      data-bs-slide="prev"
      data-bs-target="#carouselExampleCaptions15"
      type="button"
    >
      <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      data-bs-slide="next"
      data-bs-target="#carouselExampleCaptions15"
      type="button"
    >
      <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
      <span class="visually-hidden">Next</span>
    </button>

    <div class="carousel-indicators">
      <button
        aria-label="Slide 1"
        class="active"
        data-bs-slide-to="0"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts1} />
      </button>{" "}
      <button
        aria-label="Slide 2"
        data-bs-slide-to="1"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts2} />
      </button>{" "}
      <button
        aria-label="Slide 3"
        data-bs-slide-to="2"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts3} />
      </button>
      <button
        aria-label="Slide 4"
        data-bs-slide-to="3"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts4} />
      </button>
      <button
        aria-label="Slide 5"
        data-bs-slide-to="4"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts5} />
      </button>
      <button
        aria-label="Slide 6"
        data-bs-slide-to="5"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts6} />
      </button>
      <button
        aria-label="Slide 7"
        data-bs-slide-to="6"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts7} />
      </button>
      <button
        aria-label="Slide 8"
        data-bs-slide-to="7"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts8} />
      </button>
      <button
        aria-label="Slide 9"
        data-bs-slide-to="8"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts9} />
      </button>
      <button
        aria-label="Slide 10"
        data-bs-slide-to="9"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts10} />
      </button>
      <button
        aria-label="Slide 11"
        data-bs-slide-to="10"
        data-bs-target="#carouselExampleCaptions15"
        type="button"
      >
        <img class="img-thumb img-fluid" src={hangouts11} />
      </button>
    </div>
  </div>
  <div class="circles-kindness">
    <div class="center-part">
      <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
        Kindness Circles
      </div>
    </div>
  </div>
  <div
    class="carousel slide"
    data-bs-ride="carousel"
    id="carouselExampleCaptions16"
  >
    <div class="carousel-inner">
      <div class="carousel-item active bg-1">
        <div class="img-container-carousel d-flex">
          <img class="img-main" src={circles1} />
        </div>
      </div>
      <div class="carousel-item bg-2">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={circles2} />
        </div>
      </div>
      <div class="carousel-item bg-3">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={circles3} />
        </div>
      </div>
      <div class="carousel-item bg-4">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={circles4} />
        </div>
      </div>
      <div class="carousel-item bg-5">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={circles5} />
        </div>
      </div>
      <div class="carousel-item bg-6">
        <div class="img-container-carousel d-flex">
          <img class="img-main img-fluid" src={circles6} />
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      data-bs-slide="prev"
      data-bs-target="#carouselExampleCaptions16"
      type="button"
    >
      <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      data-bs-slide="next"
      data-bs-target="#carouselExampleCaptions16"
      type="button"
    >
      <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
      <span class="visually-hidden">Next</span>
    </button>

    <div class="carousel-indicators">
      <button
        aria-label="Slide 1"
        class="active"
        data-bs-slide-to="0"
        data-bs-target="#carouselExampleCaptions16"
        type="button"
      >
        <img class="img-thumb img-fluid" src={circles1} />
      </button>{" "}
      <button
        aria-label="Slide 2"
        data-bs-slide-to="1"
        data-bs-target="#carouselExampleCaptions16"
        type="button"
      >
        <img class="img-thumb img-fluid" src={circles2} />
      </button>{" "}
      <button
        aria-label="Slide 3"
        data-bs-slide-to="2"
        data-bs-target="#carouselExampleCaptions16"
        type="button"
      >
        <img class="img-thumb img-fluid" src={circles3} />
      </button>
      <button
        aria-label="Slide 4"
        data-bs-slide-to="3"
        data-bs-target="#carouselExampleCaptions16"
        type="button"
      >
        <img class="img-thumb img-fluid" src={circles4} />
      </button>
      <button
        aria-label="Slide 5"
        data-bs-slide-to="4"
        data-bs-target="#carouselExampleCaptions16"
        type="button"
      >
        <img class="img-thumb img-fluid" src={circles5} />
      </button>
      <button
        aria-label="Slide 6"
        data-bs-slide-to="5"
        data-bs-target="#carouselExampleCaptions16"
        type="button"
      >
        <img class="img-thumb img-fluid" src={circles6} />
      </button>
    </div>
  </div>
    </div>
  );
};

export default Gallery;
