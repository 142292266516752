import React from "react";
import "./Howwegothere.css";
import imagehwgh from "../../../images/Howwegothere.jpeg";

const Howwegothere = () => {
  return (
    <section class="howwegothere">
      <div class="row">
        <div class="col-md-6">
        <img class="img-fluid img-HWGH" src={imagehwgh} alt="hwgh"/>
        </div>
        <div class="col-md-6 col">
          <div>
            <h3 class="row HWGH-heading">How we got here</h3>
          </div>
          <div class="HWGH-content">
          <p>
            KU started as a movement to inspire individuals, organisations & communities to help spread kindness by sharing their privileges.
            </p>

            <p>
            In these last 16 years, KU has successfully initiated, organised & supported multiple causes, events & projects that have had a snowball effect of kindness upon the beneficiaries
            </p>

            <p>
            In fact, KU’s continuous efforts to unify & strengthen diversity with the power of kindness have provided it with the opportunity to represent the ‘World Kindness Movement’ in India.
            </p>

            <p>
            Going forward, KU aspires to transform India into 'K'India by strengthening the network of kindness by balancing self-interest with the common good.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Howwegothere;
