import React from "react";
import star from "../../../images/star.png";
import charity from "../../../images/charity.png";
import help from "../../../images/help.png";
import key from "../../../images/key.png";
import "./Differentsection.css";

const Differentsection = () => {
  return (
    <section class="mission-vision">
      <div class="center-part">
        <h1 class="main-heading-section">
          <b><span>Givers Receive, Receivers Give</span></b> 
        </h1>
        <h1 class="main-heading-section">
          Here’s what makes us <span>different</span>
        </h1>
        <div class="box-grid-diff">
          <div class="box-content-diff">
            <div class="box-details-diff">
              <div class="box-details-image"> <img src={star} alt="star" class="image-pic"/></div>
              <div class="box-details-text-diff">
              To connect and unify India's citizens through acts of kindness
              </div>
            </div>
          </div>
          <div class="box-content-diff">
            <div class="box-details-diff">
              <div class="box-details-image"> <img src={charity} alt="star" class="image-pic"/></div>
              <div class="box-details-text-diff">
              The only NGO to represent India at the ‘World Kindness Movement’
              </div>
            </div>
          </div>
          <div class="box-content-diff">
            <div class="box-details-diff">
              <div class="box-details-image"> <img src={help} alt="star" class="image-pic"/></div>
              <div class="box-details-text-diff">
              A youth - centric and volunteer - led organisation
              </div>
            </div>
          </div>
          <div class="box-content-diff">
            <div class="box-details-diff">
              <div class="box-details-image"> <img src={key} alt="star" class="image-pic"/></div>
              <div class="box-details-text-diff">
              Uses collaboration as a key strategy to help propagate kindness
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
  );
};

export default Differentsection;
