import React from "react";
import "./FAQs.css";
const FAQs = () => {
  return (
    <>
      <div class="center-part">
        <section class="faq-section">
          <h1 class="faq-heading">
            At KU, No Question Is An ‘Unkind’ Question
          </h1>
          <h3 class="faq-sub-heading">
            But first, <span class="strike">COFFEE</span>
            <span class="scolor"> FAQs!</span>
          </h3>

          <div class="content-faq">
            <div class="margin-down">
              <input
                type="checkbox"
                id="question1"
                name="q"
                class="questions"
              />
              <div class="plus">+</div>
              <label for="question1" class="question">
                Who can join KU?
              </label>
              <div class="answers">
                Anyone! Anyone who’s interested in kindness.
              </div>
            </div>

            <div class="margin-down">
              <input
                type="checkbox"
                id="question2"
                name="q"
                class="questions"
              />
              <div class="plus">+</div>
              <label for="question2" class="question">
                Is there a minimum amount that’s required for donations?
              </label>
              <div class="answers">
                No. Whether it’s a part of your monthly savings or your pocket
                money, we welcome & are grateful for every little/ major
                contribution(s) that you make.
              </div>
            </div>

            <div class="margin-down">
              <input
                type="checkbox"
                id="question3"
                name="q"
                class="questions"
              />
              <div class="plus">+</div>
              <label for="question3" class="question">
                Can I donate my time instead of money?
              </label>
              <div class="answers">
                Absolutely!
                <br />
                Time, Money, Material &/or Energy; is acknowledged & appreciated
                by us, always. We’d love to have you on board.{" "}
              </div>
            </div>

            <div class="margin-down">
              <input
                type="checkbox"
                id="question4"
                name="q"
                class="questions"
              />
              <div class="plus">+</div>
              <label for="question4" class="question">
                What will I get in return for volunteering my services at KU?
              </label>
              <div class="answers">
                100% Self-satisfaction, a sense of gratitude & a kinder version
                of you!! Also, acknowledgement certificates for the services
                rendered.
              </div>
            </div>
            <div class="margin-down">
              <input
                type="checkbox"
                id="question5"
                name="q"
                class="questions"
              />
              <div class="plus">+</div>
              <label for="question5" class="question">
                I’m raising money for a social cause happening in my city. Will
                KU help me do so too?
              </label>
              <div class="answers">
                We’re open to discussions. <br />
                Pitch us your proposal at contactus@kindnessunlimited.in
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FAQs;
