import React, { useEffect } from "react";
import "./ComNutrition.css";
import Image6 from "../../../images/Image6.png";
import Image2 from "../../../images/Image2.jpg";
import Image5 from "../../../images/Image5.jpg";
import Image4 from "../../../images/Image4.jpeg";
import { Link } from "react-router-dom";

const ComNutrition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="com-nutri">
        <div class="content-a cont-head">KU for Community & Nutrition</div>
        <div class="about-us-contents-a row">
          <div class="col-md-6 about-us-contents-left-a">
            <img class="img-fluid" src={Image6} alt="hwgh" />
          </div>

          <div class="col-md-6 com-nutri-text">
            <p>
              What can a man do with a shirt on his back & a roof on his head,
              but without a morsel in his stomach?
            </p>
            <p>
              Nothing... nothing at all, but lose his life; perhaps to a slow &
              bitter death! <br />
              According to the ‘State of Food Security and Nutrition in the
              World’, 2020 report; 14% of the Indian population suffer from
              undernourishment. Worse still, children constitute a major part of
              this percentage!
            </p>

            <p>
              Therefore, in its pursuit to abolish hunger from in & around the
              state of Maharashtra, KU initiated kindness engagements such as
              Cup Of Kindness, Go Bananas & Project Nashta, to name a few.
            </p>

            <p>
              These projects are categorised under the banner of ‘KU for
              Community & Nutrition’ and are in sync with the UN’s goal of Zero
              Hunger and Good Health & Wellbeing.
            </p>

            <p>
              Read on to know the ins & outs of what goes into making each of
              these kindness engagements impactful, memorable & a success.
            </p>
            <Link to="/donate">
            <button class="button donatehome-btn">
              Donate
            </button>
            </Link>
          </div>
        </div>
      </div>
      <div class="com-nutri-projects">
        <div class="center-part">
          <div class="notes-a">
            <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Ongoing Projects
            </p>
          </div>
          <section class="accommodation">
            <div class="stay">
              <Link to="././plantkindness" class="stay-content">
                <img src={Image2} alt="Image2" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Project Plant Kindness 2.0
                  </div>
                </div>
              </Link>

              <Link to="././nashta" class="stay-content">
                <img src={Image5} alt="hwgh" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Project Nashta
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>

        <div class="center-part">
          <div class="notes-a">
            <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Past Projects
            </p>
          </div>
          <section class="accommodation">
            <div class="stay">
              <Link to="././cupofkindness" class="stay-content">
                <img src={Image4} alt="Image4" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Cup Of Kindness
                  </div>
                </div>
              </Link>

              <Link to="././fooddistribution" class="stay-content">
                <img src={Image6} alt="hwgh" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Food Distribution
                  </div>
                </div>
              </Link>
              <Link to="././gobananas" class="stay-content">
                <img src={Image5} alt="hwgh" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Go Bananas
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ComNutrition;
