import React, { useEffect }  from "react";
import "./Upstander.css";

import upstander1 from "../../../images/upstander1.jpg";
import upstander2 from "../../../images/upstander2.jpg";
import upstander3 from "../../../images/upstander3.jpg";
import upstander4 from "../../../images/upstander4.jpg";
import upstander5 from "../../../images/upstander5.jpg";

const Upstander = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="upstander">
      <div class="col">
        <div class="row upstander-heading">Upstander – The Power of One</div>
        <div class="row upstander-content">
          The Hangout on 8th Feb highlighted through simple anecdotes the
          importance of ‘standing up’ and ‘supporting’ one another. Upstanders
          help make life meaningful for both themselves and others. We thank Mr.
          Scott Corse for guiding us in this Hangouts session.
        </div>
        <div
          class="carousel slide"
          data-bs-ride="carousel"
          id="carouselExampleCaptions"
        >
          <div class="carousel-inner">
            <div class="carousel-item active bg-1">
              <div class="img-container-carousel d-flex">
                <img class="img-main" src={upstander1} />
              </div>
            </div>
            <div class="carousel-item bg-2">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={upstander2} />
              </div>
            </div>
            <div class="carousel-item bg-3">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={upstander3} />
              </div>
            </div>
            <div class="carousel-item bg-4">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={upstander4} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={upstander5} />
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            data-bs-slide="prev"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            data-bs-slide="next"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
            <span class="visually-hidden">Next</span>
          </button>

          <div class="carousel-indicators">
            <button
              aria-label="Slide 1"
              class="active"
              data-bs-slide-to="0"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={upstander1} />
            </button>{" "}
            <button
              aria-label="Slide 2"
              data-bs-slide-to="1"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={upstander2} />
            </button>{" "}
            <button
              aria-label="Slide 3"
              data-bs-slide-to="2"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={upstander3} />
            </button>
            <button
              aria-label="Slide 4"
              data-bs-slide-to="3"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={upstander4} />
            </button>
            <button
              aria-label="Slide 5"
              data-bs-slide-to="4"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={upstander5} />
            </button>
          </div>
        </div>
        <div class="row upstander-content">
          <span class="sub-heading">About the Kindness Hangouts:</span><br/> The ‘Kindness Hangout’ is a monthly
          discussion forum, driven to encourage conversations. The larger
          purpose is to call upon people from various walks of life, each
          committed to driving social change. The platform is intimate and
          advocates dialogue and discourse, thus allowing for the cross
          pollination of ideas, the cultivation of mutual respect and increased
          sensitisation and awareness.
        </div>
      </div>
    </section>
  );
};

export default Upstander;
