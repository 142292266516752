import React from "react";
import "./NumberCounter.css";
import givers from "../../../images/givers.png";
import recievers from "../../../images/recievers.png";
import observers from "../../../images/observers.png";

const NumberCounter = () => {
  return (
    <section class="number-counter">
      
  <div class="albums row">
    <div class="counter_section col-lg-4 col-md-6">
        <h2>Givers x 2</h2>
        <img src={givers} alt="" height="100" class="photo_counter" />
        <div class="numbers">
      <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--1"
          data-fake="3681651261"
          >2</span> 
        </span>
      <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6207186394"
          >2</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="7149822942"
          >0</span
        > </span>
        ,
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815189853"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="3516818468"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815105864">
            0
            </span> 
        </span>+
    </div>
    </div>
    <div class="counter_section col-lg-4 col-md-6">
        <h2>Receivers X 2</h2>
        <img src={recievers} alt="" height="100" class="photo_counter" />

        <div class="numbers">
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815105864">
            5
            </span> 
        </span>
        ,
      <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--1"
          data-fake="3681651261"
          >6</span> 
        </span>
      <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6207186394"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="7149822942"
          >0</span
        > </span>
        ,
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815189853"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="3516818468"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815105864">
            0
            </span> 
        </span>+
    </div>
    </div>
    <div class="counter_section  col-lg-4 col-md-6">
        <h2>Observers</h2>
        <img src={observers} alt="" height="100" class="photo_counter" />

        <div class="numbers">
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815105864">
            8
            </span> 
        </span>,
      <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--1"
          data-fake="3681651261"
          >2</span> 
        </span>
      <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6207186394"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="7149822942"
          >0</span
        > </span>
        ,
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815189853"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="3516818468"
          >0</span
        > </span>
        <span class="numbers__window">
        <span
          class="numbers__window__digit numbers__window__digit--2"
          data-fake="6815105864">
            0
            </span> 
        </span>+
        
    </div>
    </div>
    </div>
    </section>
  );
};

export default NumberCounter;
