import React,{ useRef }  from "react";
import "./ContactUs.css";
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";


const ContactUs = () => {

  const form = useRef();

  function sendEmail (e) {
    e.preventDefault();

   emailjs.sendForm('service_s3p4bs9', 'template_0mn3b5m', e.target, 'sxNZPEOtLzIM0BOV_')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
      alert("Your form has been submitted, we will get back to you later.")
  };

  return (
    <div class="center-part contactus-section">
      <section>
        <h1 class="contactus-heading">Contact Us</h1>
        <h3 class="contactus-sub-heading">
          Have a different ask, message, suggestion or proposal?
        </h3>
        <h3 class="contactus-sub-heading">
          Reach out to us at{" "}
          <a href="mailto:contactus@kindnessunlimited.in" class="email">
            contactus@kindnessunlimited.in
          </a>
          <br/><br/>
          <Link to="/opportunities">
            Click here if you are willing to volutneer or work with us
          </Link>
        

        </h3>
      </section>
      <section class="contact-form-section">
        <h1 class="contactus-heading">In a hurry?</h1>

        <h3 class="contactus-sub-heading">
          No worries! Drop-in your message here
        </h3>
    
        {/* form  */}
        <div class="container-contact">
          <form id="contact" action="" ref={form} onSubmit={sendEmail}>
          <div class="row mx-auto">
          <h3 class="contactus-sub-heading">Reach Out Now</h3>
            <fieldset>
              <input
                placeholder="Name"
                type="text"
                tabindex="1"
                required
                autofocus
                name="name"
              />
            </fieldset>
            <fieldset>
              <input
                placeholder="Email Address"
                type="email"
                tabindex="2"
                required
                name="email"
              />
            </fieldset>
            <fieldset>
              <textarea
                placeholder="Message"
                tabindex="5"
                required
                name="message"
              ></textarea>
            </fieldset>
            <fieldset>
              <button
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
                value="Send"
              >
                Submit
              </button>
            </fieldset>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
