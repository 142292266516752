import React, { useEffect }  from "react";

const GoBananas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="about-us">
      <div class="center-part">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
          Go Bananas
        </div>

        <div class="story-grid">
          <div class="content">
            <p class="text">
              In early April, ‘19 while the world was going bananas with the
              rumours of the ongoing pandemic catching fire; we at KU realised
              that with the nationwide lockdown, things were going to get only
              tougher.
              <p class="text">
                <br />
                So, we reached out to Mr Shishir Joshi, founder of the NGO-
                Project Mumbai. Project Mumbai had already been doing some
                amazing work on the ground & they, along with the Holy Cross
                School of Kurla, quickly became our execution partners to help
                us find a way to distribute-
              </p>
              <p class="text">
                5000 bananas each day to the children of daily wage earners,
                migrant workers and slum dwellers all across Mumbai.
              </p>
            </p>
            <div class="iframe-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ZbKKCG-VUig"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style={{height: "100%"}}
              ></iframe>
            </div>
            <p>
                Thereafter, we launched a crowdfunding campaign on Milap. Kind
                people like you, helped us close our crowdfunding goal of Rs
                3,00,000 and more!
                <br />
                With a gradual increase in the donations received, we could
                reach out to more & more children, with these nutritious gifts
                of kindness.
              </p>
              <p>
                Thanks to contributions from kind people like You, a whooping
                1,25,000+ bananas were distributed via this kindness project!
                <br />
                Truly, Go Bananas was indeed a one of a kind virtual service
                project that witnessed the power of collaboration & the ripple
                effect of going bananas- with one’s kindness!
              </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoBananas;
