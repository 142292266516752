import React,{ useEffect }  from "react";
import "./OurTeam.css";
import nirmala from "../../images/Nirmala_Mehendale-2-1.jpg";
import lopa from "../../images/lopa.jpg";

import sonal from "../../images/Trustee-Sonal-Singh_resized.jpg";
import sunita from "../../images/Trustee-Sunita-Machado-1.jpg";
import venkat from "../../images/Venkat_500x500.jpg";
import kavya from "../../images/Kavya-Bhola.jpg";
import simi from "../../images/Simi-Kuriakose.jpg";
import sriya from "../../images/Sriya-Ganesh.jpg";
import ayushi from "../../images/ayushi.jpg";
import aditya from "../../images/aditya.jpg";
import hansie from "../../images/Hansie_1.jpg";
import nijo from "../../images/nijo.jpg";
import arya from "../../images/Arya-Karandikar-1.jpg";
import samuel from "../../images/Samuel.jpg";
import basilica from "../../images/Basilica.png";

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="team">
      <h1 class="team-heading">Our Team</h1>
      <div class="members-section">
        <div class="row bboKU">
          <h2 class="sub-heading">Building Blocks of KU</h2>
          <div class="person-card col-lg-3 col-md-4">
            <div class="col card-col">
              <div class="row member-img">
                <img
                  class="img-fluid member-photo"
                  src={nirmala}
                  alt="nirmala"
                />
              </div>
              <div class="row member-name">
                <p>Nirmala Peters - Mehendale</p>
              </div>
              <div class="row member-designation">
                <p>Founder Trustee and General Secretary</p>
              </div>
            </div>
          </div>
          <div class="person-card col-lg-3 col-md-4">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={lopa} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Lopa Vyas</p>
              </div>
              <div class="row member-designation">
                <p>Founder Trustee</p>
              </div>
            </div>
          </div>
          <div class="person-card col-lg-3 col-md-4">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={sonal} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Sonal Singh</p>
              </div>
              <div class="row member-designation">
                <p>Trustee</p>
              </div>
            </div>
          </div>
          <div class="person-card col-lg-3 col-md-4">
            <div class="col card-col">
              <div class="row member-img">
                <img
                  class="img-fluid member-photo"
                  src={sunita}
                  alt="nirmala"
                />
              </div>
              <div class="row member-name">
                <p>Sunita Machado</p>
              </div>
              <div class="row member-designation">
                <p>Trustee</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="members-section">
        <div class="row bboKU">
          <h2 class="sub-heading">KU Mentor</h2>
          <div class="person-card">
            <div class="col card-col">
              <div class="row member-img">
                <img
                  class="img-fluid member-photo"
                  src={venkat}
                  alt="nirmala"
                />
              </div>
              <div class="row member-name">
                <p>Venkat N. Krishnan</p>
              </div>
              <div class="row member-designation">
                <p>Mentor</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="members-section">
        <div class="row bboKU">
          <h2 class="sub-heading">KU Youth Council</h2>
          <div class="person-card col-md-4">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={kavya} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Kavya Bhola</p>
              </div>
            </div>
          </div>
          <div class="person-card col-md-4">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={simi} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Simi Kuriakose</p>
              </div>
            </div>
          </div>
          <div class="person-card col-md-4">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={sriya} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Sriya Ganesh</p>
              </div>
            </div>
          </div>
          <div class="person-card col-md-6">
            <div class="col card-col">
              <div class="row member-img">
                <img
                  class="img-fluid member-photo"
                  src={ayushi}
                  alt="nirmala"
                />
              </div>
              <div class="row member-name">
                <p>Ayushi Khare</p>
              </div>
            </div>
          </div>
          <div class="person-card col-md-6">
            <div class="col card-col">
              <div class="row member-img">
                <img
                  class="img-fluid member-photo"
                  src={aditya}
                  alt="nirmala"
                />
              </div>
              <div class="row member-name">
                <p>Aditya Sen</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="members-section">
        <div class="row bboKU">
          <h2 class="sub-heading">KU Interns</h2>
          <div class="person-card col-lg-4 col-md-6">
            <div class="col card-col">
              <div class="row member-img">
                <img
                  class="img-fluid member-photo"
                  src={hansie}
                  alt="nirmala"
                />
              </div>
              <div class="row member-name">
                <p>Hansie Aloj</p>
              </div>
              <div class="row member-designation">
                <p>Website Manager</p>
              </div>
            </div>
          </div>
          <div class="person-card col-lg-4 col-md-6">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={nijo} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Nijo Ninan</p>
              </div>
              <div class="row member-designation">
                <p>Website Manager</p>
              </div>
            </div>
          </div>
          <div class="person-card col-lg-4 col-md-6">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={basilica} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Basilica Anthony</p>
              </div>
              <div class="row member-designation">
                <p>Website Manager</p>
              </div>
            </div>
          </div>
          <div class="person-card col-lg-6 col-md-6">
            <div class="col card-col">
              <div class="row member-img">
                <img class="img-fluid member-photo" src={arya} alt="nirmala" />
              </div>
              <div class="row member-name">
                <p>Arya Karandikar</p>
              </div>
              <div class="row member-designation">
                <p>Media and Communication</p>
              </div>
            </div>
          </div>
          <div class="person-card col-lg-6 col-md-6">
            <div class="col card-col">
              <div class="row member-img">
                <img
                  class="img-fluid member-photo"
                  src={samuel}
                  alt="nirmala"
                  
                />
              </div>
              <div class="row member-name">
                <p>Samuel</p>
              </div>
              <div class="row member-designation">
                <p>Illustrator and Designer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
