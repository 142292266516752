import React from "react";
import "./MissionVision.css";
import visionPhoto  from "../../../images/visionPhoto.jpeg"


const MissionVision = () => {
  return (
    <section class="mission-vision">
      <div class="center-part-mv">
        <div class="mission-heading">
        KU aspires to strengthen the network of the good.
        </div>
        <div class="mission-heading">
        Kindness is a Boomerang.        </div>
        <div class="mission-heading">
        Working with youth towards building a united and inclusive nation        </div>
        <div class="box-grid-mission">
          <div class="box-content">
            <div class="box-details">
              <div class="box-details-name">OUR MISSION</div>
              <div class="box-details-text align-middle">
              To transform mindsets to more sharing of time, resources, skills, knowlege and money. To celebrate the up standers in society.
              </div>
            </div>
          </div>
          <div class="box-content-m">
            <div class="box-details">
              <div class="box-details-name">
                Our Definition of Kindness
              </div>
              <div class="box-details-text align-middle">
              Balancing self-interest with the Common Good.
              </div>
            </div>
          </div>
          <div class="box-content">
            <div class="box-details">
              <div class="box-details-name">OUR VISION</div>
              <div class="box-details-text">
              <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={visionPhoto} />
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
