import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/WWD/About";
import OurStory from "./components/OurStory/OurStory";
import Contact from "./components/Contact/Contact";
import ProjectKUL from "./components/ProjectKUL/ProjectKUL";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Opportunities from "./components/Opportunities/Opportunities";
import Inspirations from "./components/Inspirations/Inspirations";
import Engagements from "./components/Engagements/Engagements";
import ComNutrition from "./components/Engagements/ComNutrition/ComNutrition";
import Education from "./components/Engagements/Education/Education";
import Arts from "./components/Engagements/Arts/Arts";
import Hangouts from "./components/Engagements/Hangouts/Hangouts";
import WKW from "./components/Engagements/WKW/WKW";
import PlantKindness from "./components/Engagements/PlantKindness/PlantKindness";
import KCCollege from "./components/Engagements/KCCollege/KCCollege";
import KindnessMusic from "./components/Engagements/KindnessMusic/KindnessMusic";
import Zumba from "./components/Engagements/Zumba/Zumba";
import Upstander from "./components/Engagements/Upstander/Upstander";
import KindnessJam from "./components/Engagements/KindnessJam/KindnessJam";
import Beats from "./components/Engagements/Beats/Beats";
import Musical from "./components/Engagements/Musical/Musical";
import DanceKindness from "./components/Engagements/DanceKindness/DanceKindness";
import Kohinoor from "./components/Engagements/Kohinoor/Kohinoor";
import KindnessCircles from "./components/Engagements/KindnessCircles/KindnessCircles";
import DonateAToi from "./components/Engagements/DonateAToi/DonateAToi";
import Nashta from "./components/Engagements/Nashta/Nashta";
import Carnival from "./components/Engagements/Carnival/Carnival";
import CupofKindness from "./components/Engagements/CupofKindness/CupofKindness";
import GoBananas from "./components/Engagements/GoBananas/GoBananas";
import FoodDistribution from "./components/Engagements/FoodDistribution/FoodDistribution";
import Gallery from "./components/Gallery/Gallery";
import Newsroom from "./components/Newsroom/Newsroom";
import Collaborations from "./components/Collaborations/Collaborations";
import SYT from "./components/SYT/SYT";
import WKM from "./components/WKM/WKM";
import OurTeam from "./components/OurTeam/OurTeam";
import Donate from "./components/Donate/Donate";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";


function App() {
  return (
    <BrowserRouter>
      <div class="app">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wwd" element={<About />} />
          <Route path="/wkm" element={<WKM />} />
          <Route path="/ourstory" element={<OurStory />} />
          <Route path="/ourteam" element={<OurTeam />} />
          <Route path="/opportunities" element={<Opportunities />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/engagements" element={<Engagements />} />
          <Route path="/engagements/nutrition" element={<ComNutrition />} />
          <Route path="/engagements/nutrition/plantkindness" element={<PlantKindness />} />
          <Route path="/engagements/nutrition/nashta" element={<Nashta />} />
          <Route path="/engagements/nutrition/cupofkindness" element={<CupofKindness />} />
          <Route path="/engagements/WKW/cupofkindness" element={<CupofKindness />} />
          <Route path="/engagements/nutrition/gobananas" element={<GoBananas />} />
          <Route path="/engagements/nutrition/fooddistribution" element={<FoodDistribution />} />
          <Route path="/engagements/WKW/fooddistribution" element={<FoodDistribution />} />
          <Route path="/engagements/education" element={<Education />} />
          <Route path="/engagements/arts" element={<Arts />} />
          <Route path="/engagements/arts/musical" element={<Musical />} />
          <Route path="/engagements/arts/zumba" element={<Zumba />} />
          <Route path="/engagements/arts/kindnessjam" element={<KindnessJam />} />
          <Route path="/engagements/WKW/kindnessjam" element={<KindnessJam />} />
          <Route path="/engagements/arts/beats" element={<Beats />} />
          <Route path="/engagements/WKW/beats" element={<Beats />} />
          <Route path="/engagements/arts/kindnessmusic" element={<KindnessMusic />} />
          <Route path="/engagements/arts/dancekindness" element={<DanceKindness />} />
          <Route path="/engagements/WKW/dancekindness" element={<DanceKindness />} />
          <Route path="/engagements/WKW/carnival" element={<Carnival />} />
          <Route path="/engagements/hangouts" element={<Hangouts />} />
          <Route path="/engagements/hangouts/upstander" element={<Upstander />} />
          <Route path="/engagements/WKW" element={<WKW />} />
          <Route path="/engagements/education/KCCollege" element={<KCCollege />} />
          <Route path="/engagements/education/kindnesscircles" element={<KindnessCircles />} />
          <Route path="/engagements/education/kohinoor" element={<Kohinoor />} />
          <Route path="/engagements/education/donateatoi" element={<DonateAToi />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/project-kul" element={<ProjectKUL />} />
          <Route path="/inspirations" element={<Inspirations />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/newsroom" element={<Newsroom />} />
          <Route path="/collaborations" element={<Collaborations />} />
          <Route path="/syt" element={<SYT />} />
          <Route path="/donate" element={<Donate/>}/>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;