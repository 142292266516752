import React, { useEffect }  from "react";
import "./CupofKindness.css";

const CupofKindness = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="about-us">
      <div class="center-part">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
          Cup of Kindness
        </div>

        <div class="story-grid">
          <div class="content">
            <div class="iframe-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/-3fUNnLb7WU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p class="text">
              Lockdown 1.0 made each one of us realise the importance of house
              essentials such as utilities, toiletries & groceries including the
              hero of the ‘Cup of Kindness’ project- the humble TEA. So, while
              Team KU was already involved in the project Go Bananas...
              <p class="text">
                <br />
                We thought it would be extremely unkind to deprive the
                have-nots, of one of life’s simplest and purest pleasures- the
                joy of drinking a cup of tea!
              </p>
              <p class="text">
                Hence, to bring the magic of tea back to them, Kindness
                Unlimited with the support of Society Tea and our execution
                partner Make a Difference, carried out a tea distribution drive
                through 12 remote villages in Maharashtra. We did this to flag
                off World Kindness Week 2019.
                <br />
                In fact, our campaign #CupofKindness encouraged others to share
                their own acts of kindness which they pledged to take up by
                dropping a small note about it in their own little cups at home.
              </p>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CupofKindness;
