import React, { useEffect }  from "react";
import "./Carnival.css";

import carnival1 from "../../../images/carnival1.jpg";

const Carnival = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="carnival">
      <div class="col">
        <div class="row circles-heading">Kindness Carnival</div>
        <div class="row why-kindness row">
          <div class="row why-circle-heading">
            Welcome to The KINDNESS CARNIVAL 2020.
          </div>
          <div class="col col-md-8 row circles-content">
            We hope you are all safe, sound, and are taking good care of
            yourselves.
            <br />
            The event we all are eagerly waiting for is nearing and we hope you
            are just as excited as we are. The details for the Kindness Carnival
            are:
            <br />
            Date: 7th of November 2020
            <br />
            Time: 4-6 pm
            <div class="link-container">
              <a class="link" href="https://carnival.kindnessunlimited.in/explore">
                https://carnival.kindnessunlimited.in/explore
              </a>
            </div>
            Let’s come together, experience what Kindness feels like, and make
            this world a better place. We hope to see all of you.
          </div>
          <div class="col-md-4 photo-container">
            <img class="photo-kc" src={carnival1} alt="hwgh" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carnival;
