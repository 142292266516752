import React from "react";
import Internships from "./Internships/Internships";
import Partnership from "./Partnership/Partnership";
import Query from "./Query/Query";
import Volunteering from "./Volunteering/Volunteering";

const Opportunities = () => {
  return (
    <>
    <Internships/>
    <Volunteering/>
    <Partnership/>
    <Query/>
    </>
  );
};

export default Opportunities;
