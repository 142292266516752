import React from "react";
import "./Testimonial.css";
import { Link } from "react-router-dom";


import Khushi from "../../../images/KhushiGupta.png";
import Pratham from "../../../images/PrathamMalik.jpg";
import Kavya from "../../../images/KavyaShukla.jpg";

const Testimonial = () => {
  return (
    <section class="testimonal">
      <h3 class="heading-testimonal">
        Here’s what people have to say about KU & its projects!
      </h3>
      <h5 class="sub-heading">Testimonals</h5>
      <div class="feedback row">
        <div class="feedback-person col-md-4">
          <div class="feedback-text">
            <p>
              I liked how I personally got to know different kinds of kindness
              acts people do.
            </p>
          </div>
          <div class="feedback-person-details">
            <img class="testimonial-img" src={Kavya} alt="person-1" />
            <div class="user-details">
              <div class="user-name">Kavya Shukla</div>
            </div>
          </div>
        </div>

        <div class="feedback-person col-md-4">
          <div class="feedback-text">
            <p>
              My SPOC was Aditya sir. He was really cooperative & he was quick
              in solving our queries moreover he went an extra mile for all of
              us by organising short meetings every week which were really fun.
              Thank You Aditya sir for such a wonderful experience. Grateful for
              you :)
            </p>
          </div>
          <div class="feedback-person-details">
            <img class="testimonial-img" src={Pratham} alt="person-1" />
            <div class="user-details">
              <div class="user-name">Pratham Malik</div>
            </div>
          </div>
        </div>

        <div class="feedback-person col-md-4">
          <div class="feedback-text">
            <p>
              Interacting with people and getting to know their best experiences
              was very therapeutic. I realized that kindness hasn't vanished
              from the world.
            </p>
          </div>
          <div class="feedback-person-details">
            <img class="testimonial-img" src={Khushi} alt="person-1" />
            <div class="user-details">
              <div class="user-name">Khushi Gupta</div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/donate">
        <div class="donate-btn">
          <button class="button kindness-btn ">DONATE</button>
        </div>
      </Link>
    </section>
  );
};

export default Testimonial;
