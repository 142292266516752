import React from "react";
import "./Partners.scss";
import connectofr from "../../../images/sponsors/connectofr.png"
import DAN from "../../../images/sponsors/DAN.png"
import django from "../../../images/sponsors/django.png"
import Drona from "../../../images/sponsors/Drona-Foundation.png"
import Holy from "../../../images/sponsors/Holy-Cross-Kurla.png"
import Jamnabai from "../../../images/sponsors/Jamnabai-Narsee-School-black.png"
import kc  from "../../../images/sponsors/kc-college-1.png"
import Kohinoor from "../../../images/sponsors/Kohinoor-Business-School_1.png"
import Life_Vest_Inside_Logo_transparent from "../../../images/sponsors/Life_Vest_Inside_Logo_transparent.png"
import Little from "../../../images/sponsors/Little-Sisters-of-the-Poor.png"
import MAD from "../../../images/sponsors/MAD_LOGO_11-4-17-1.png"
import mcubed from "../../../images/sponsors/MCubed-Library.png"
import roti from "../../../images/sponsors/Mumbai-Roti-Bank.png"
import NSPA from "../../../images/sponsors/NSPA.png"
import parleshwar from "../../../images/sponsors/parleshwar-1.png"
import rotary from "../../../images/sponsors/Rotary-Club.png"
import schbang from "../../../images/sponsors/schbang.png"
import smash from "../../../images/sponsors/Smaaash.png"
import sparsha from "../../../images/sponsors/Sparsha-Charitable-Trust_transparency.png"
import andrews from "../../../images/sponsors/St.-Andrews-college.png"
import TitleWaves from "../../../images/sponsors/Title-Waves.png"
import toiing from "../../../images/sponsors/toiing.png"
import trilogy from "../../../images/sponsors/Trilogy.png"
import villa from "../../../images/sponsors/Villa-Teresa-school.png"
import sporting from "../../../images/sponsors/WhatsApp-Image-2020-03-15-at-8.19.45-AM-1.png"
import projectMumbai from "../../../images/sponsors/WhatsApp-Image-2021-02-15-at-22.54.58-1.png"
import society from "../../../images/sponsors/WhatsApp-Image-2021-02-15-at-22.54.58-2.png"

const Partners = () => {
  return (

	<section class="partners">
		<h1 class="our-clients">Our Kindness Ambassadors</h1>

<div class="slider">
	<div class="slide-track">
		<div class="slide">
			<img src={connectofr} height="100" width="100" alt="" />
		</div>
		<div class="slide">
    <img src={DAN} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={Holy} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={django} height="100" width="100" alt="" />		</div>
		<div class="slide">
		<img src={Drona} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={Holy} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={Jamnabai} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={kc} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={Kohinoor} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={Life_Vest_Inside_Logo_transparent} height="100" width="100" alt="" />		</div>
		<div class="slide">
    <img src={Little} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={MAD} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={mcubed} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={roti} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={NSPA} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={parleshwar} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={rotary} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={schbang} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={smash} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={sparsha} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={andrews} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={TitleWaves} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={toiing} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={trilogy} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={villa} height="100" width="100" alt="" />		</div>
	<div class="slide">
     <img src={sporting} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={projectMumbai} height="100" width="100" alt="" />		</div>
	<div class="slide">
    <img src={society} height="100" width="100" alt="" />		</div> 

	</div>
  </div>
	</section>
        

    );
};

export default Partners;