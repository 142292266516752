import React,{ useEffect }  from "react";
import ASection1 from "./ASection1/ASection1";
import ASection2 from "./ASection2/ASection2";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <ASection1/>
    <ASection2/>
    </>
  );
};

export default About;
