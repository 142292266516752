import React, { useEffect }  from "react";
import "./Zumba.css";
import Zumba1 from "../../../images/zumba1.jpg";
import Zumba2 from "../../../images/zumba2.jpg";
import Zumba3 from "../../../images/zumba3.jpg";
import Zumba4 from "../../../images/zumba4.jpg";
import Zumba5 from "../../../images/zumba5.jpg";
import Zumba6 from "../../../images/zumba6.jpg";

const Zumba = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="zumba">
      <div class="col">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
        Zumba Session for Wheelchair bound
        </div>
        <p class="text">
          Along with the members of the Nina foundation for spinal cord
          injuries, we conducted a very fun and energizing zumba session, thanks
          to JustAllHeart. We enjoyed an evening filled with lots of dance, love
          and laughter!
        </p>
        <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={Zumba1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba5} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Zumba6} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Zumba5} />
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Zumba;
