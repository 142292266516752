import React, { useEffect }  from "react";
import "./DanceKindness.css";

import dance1 from "../../../images/dance-kindness1.jpg";
import dance2 from "../../../images/dance-kindness2.jpg";
import dance3 from "../../../images/dance-kindness3.jpg";
import dance4 from "../../../images/dance-kindness4.jpg";
import dance5 from "../../../images/dance-kindness5.jpg";
import dance6 from "../../../images/dance-kindness6.jpg";
import dance7 from "../../../images/dance-kindness7.jpg";
import dance8 from "../../../images/dance-kindness8.jpg";
import dance9 from "../../../images/dance-kindness9.jpg";
import dance10 from "../../../images/dance-kindness10.jpg";
import dance11 from "../../../images/dance-kindness11.jpg";
import dance12 from "../../../images/dance-kindness12.jpg";
import dance13 from "../../../images/dance-kindness13.jpg";

const DanceKindness = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="dance-kindness">
      <div class="center-part">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
          Dance for Kindness
        </div>

        <div class="story-grid">
          <div class="content">
            <div class="video-container">
              <iframe
                height="549"
                src="https://www.youtube.com/embed/UpdxVuZwvDI"
                title="Dance for Kindness Mumbai 2014"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="video-container">
              <iframe
                height="549"
                src="https://www.youtube.com/embed/Floq0FkcEEs"
                title="Worldwide Dance for Kindness- Mumbai 2013"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <p class="dance-kindness-content text">
            In the November of 2013 and 2014, as a way to launch World Kindness
            Week, Kindness Unlimited in association with Life Vest Inside, a
            U.S. based NPO, organized a flash dance across the country that
            brought together hundreds of people from different backgrounds to
            stand united and dance together under the banner of kindness, a move
            that showed the overwhelming public support towards our shared
            vision for a kinder world.
          </p>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={dance1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance6} />
            </div>
          </div>
          <div class="carousel-item bg-7">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance7} />
            </div>
          </div>
          <div class="carousel-item bg-8">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance8} />
            </div>
          </div>
          <div class="carousel-item bg-9">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance9} />
            </div>
          </div>
          <div class="carousel-item bg-10">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance10} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance11} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance12} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={dance13} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance8} />
          </button>
          <button
            aria-label="Slide 9"
            data-bs-slide-to="8"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance9} />
          </button>
          <button
            aria-label="Slide 10"
            data-bs-slide-to="9"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance10} />
          </button>
          <button
            aria-label="Slide 11"
            data-bs-slide-to="10"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance11} />
          </button>
          <button
            aria-label="Slide 12"
            data-bs-slide-to="11"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance12} />
          </button>
          <button
            aria-label="Slide 13"
            data-bs-slide-to="12"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={dance13} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DanceKindness;
