import React from "react";
import "./ProjectKUL.css";
import Image2 from "../../images/KUL-image1.jpg";
import Video1 from "../../images/KUL-video1.mp4";
import Video2 from "../../images/KUL-video2.mp4";

const ProjectKUL = () => {
  return (
    <div className="opp-1">
      <div className="content-a-opp cont-head-opp ccbold-opp">Project-KUL</div>
      <div className="about-us-contents-a">
        
        {/* Section 1: Text Left, Image Right */}
        <div className="about-us-contents-left-a">
          <p>
            The Kindness Unlimited Collective was launched this academic year 2024-25. 
            We have begun a pilot project with action research led by 2 TISS professors that 
            will explore the correlation between ‘volunteering and mental well-being’. The experience 
            also aims to empower undergraduate students with life skills learnt through service.
          </p>
        </div>
        <div className="about-us-contents-right-a">
          <img className="business-case-image" src={Image2} alt="Project KUL" />
        </div>

        {/* Section 2: Video Left, Text Right */}
        <div className="about-us-contents-left-a">
          <video className="video" controls>
            <source src={Video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="about-us-contents-right-a">
          <p>
            The project began at two Mumbai colleges, Sophias and St. Andrews, where we have offered a planned 30 hours of volunteering followed by deep reflection sessions.
            We plan to have the research published by June 2025 and scale to other colleges.
          </p>
        </div>

        {/* Section 3: Text Left, Video Right */}
        <div className="about-us-contents-left-a">
          <p>
            The focus is on urban youth who are leaders of tomorrow. The objective is that a percentage of participating youth may continue to become lifelong volunteers and be of service, experience the joys of giving, and become more responsible citizens.
            The approach is threefold: K3 - Kindness to Self, Kindness to Others, and Kindness to the Environment. The focus is preferably on Kindness within the college and around the college pin code by contributing to the community and looking for opportunities to give and be of service.

          </p>
        </div>
        <div className="about-us-contents-right-a">
          <video className="video" controls>
            <source src={Video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Section 4: Text Right, Video Left */}
        <div className="about-us-contents-left-a">
          <p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectKUL;
