import React, { useEffect }  from "react";
import Introduction from "./Introduction/Introduction";
import Projects from "./Projects/Projects";


const Engagements = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Introduction/>
    <Projects/>
    </>
  );
};

export default Engagements;