import React from "react";
import "./SYT.css";
import Image2  from "../../images/Image2.jpg";
import Varvathne  from "../../images/Varvathne-2.jpg";


const SYT = () => {
  return <div class="opp-1">
  <div class="cont-head-opp content-a-opp contcolor-opp">
    You’ve got talents, we’ve got opportunities
  </div>
  <div class="content-a-opp cont-head-opp ccbold-opp">
    Don’t Leave Your Special Talents At Home
  </div>
  <div class="about-us-contents-a">
    <div class="about-us-contents-left-a" style={{textAlign:"left"}}>

    <div class="notes-a">
        <p>
        Your path to kindness is awaiting.
        </p>

        <p>
        At KU, we believe that everybody, yes, everybody has a speciality. A
              special talent that could help us in our mission of connecting &
              unifying people, by having them perpetually initiate acts of kindness,
              big or small.
        </p>

        <p>
        A special talent that perhaps you’re so passionate about, you’d not
              mind sharing it with the less privileged- for FREE!
        </p>

        <p>
        But, the question is:
        </p>

        <iframe
    class="video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/UpdxVuZwvDI"
            title="Dance for Kindness Mumbai 2014"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
      </div>
    </div>

    <div class="about-us-contents-right-a">
    <iframe
    class="video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/BUyt7h5I9PM"
            title="Volunteers @Kindness Unlimited"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
    
          <div class="content-a cont-head" style={{paddingBottom: "10px"}}>Are you ready to share your talents with the world?</div>

<p>
If your answer is a loud YES IN RED, then dear kind soul, we invite you to read through the different kindness opportunities listed below.
</p>
<p>
Whether a fresher or a veteran, a writer or a designer; <br/>there're ‘kindness opportunities’ for one & all!
</p>
<p>
Inviting you to be a part of our kindness journey to share your talents & help us transform India into KINDia.
</p>
    </div>
  </div>
</div>
};

export default SYT;


