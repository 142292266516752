import React from "react";
import "./HeroSection.css";
import imagehero from "../../../images/ImageforHeroSection.jpeg";
import KUhomepage from "../../../images/KU_homepage.jpg";
import CNBCTV18 from "../../../images/media/1.png";
import KUjam from "../../../images/KU_jam.mp4"
import CoverImage from "../../../images/coverimage.jpeg"

import { Link } from "react-router-dom";


const HeroSection = () => {
  return (
    <div class="center-part">
      <section id="testimonials">
        <div
          id="carouselExampleIndicators"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
          data-interval="10"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          
          <div class="carousel-inner">
          <div class="carousel-item active">
              <section class="hero-section carousel1" >
                <div class="hero-words">
                  <h3 class="sub-heading">Daan Utsav</h3>
                  <h1 class="main-headin">
                  Kindness Unlimted celebrates Daan Utsav and World Kindness week ,  To volunteer and get a free ticket to the Kindness  Jam 
                  </h1>
                  <Link to="https://connectfor.org/group-weekend-volunteering">
                  <button class="button kindness-btn">
                    Please click here 
                  </button>
                  </Link>
                </div>
                <div class="hero-image">
                <video id="myVideo" src={KUjam} width="300" height="150" poster={CoverImage} controls={true} loop={true} autoPlay={true} muted>
                </video>
                </div>
              </section>
            </div>
            <div class="carousel-item">
              <section class="hero-section carousel1" >
                <div class="hero-words">
                  <h3 class="sub-heading">Unlock the kindness in you by</h3>
                  <h1 class="main-heading">
                    Balancing <br /> Self-Interest With The Common Good!
                  </h1>
                  <Link to="/contactus">
                  <button class="button kindness-btn">
                    START YOUR KINDNESS JOURNEY
                  </button>
                  </Link>
                </div>
                <div class="hero-image">
                  <img src={imagehero} alt="imagehero" />
                </div>
              </section>
            </div>
            <div class="carousel-item">
              <section class="hero-section carousel2">
                <div class="hero-words">
                  <h3 class="sub-heading">Kindness Unlimited</h3>
                  <h1 class="main-heading">
                    Connnecting<br /> India through the web of kindness!
                  </h1>
                  <Link to="/contactus">
                  <button class="button kindness-btn">
                    START YOUR KINDNESS JOURNEY
                  </button>
                  </Link>
                </div>
                <div class="hero-image">
                  <img src={KUhomepage} alt="imagehero" />
                </div>
              </section>
            </div>
            <div class="carousel-item">
              <section class="hero-section carousel3">
                <div class="hero-words">
                  <h3 class="sub-heading">The Heart of Business</h3>
                  <h3>CNBCTV18 Live panel discussion</h3>
                  <h1 class="main-heading">
                  Sharing about <br/>it's Cool to be Kind
                  </h1>
                  <a href="https://www.facebook.com/watch/live/?v=708190700044584">
                  <button class="button kindness-btn">
                    Learn More
                  </button>
                  </a>
                </div>
                <div class="hero-image">
                  <img src={CNBCTV18} alt="imagehero" />
                </div>
              </section>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
