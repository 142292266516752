import React from "react";
import Image7 from "../../../images/Image7.jpeg";
import hunger from "../../../images/hunger.png";
import peace from "../../../images/peace.png";
import goodheart from "../../../images/good-heart.png";
import gender from "../../../images/gender.png";
import scale from "../../../images/scale.png";
import "./Introduction.css";

const Introduction = () => {
  return (
    <div class="intro-engagements">
      <div class="about-1">
      <div class="engangements-heading">Kindness Engagements</div>

        <div class="about-us-contents-a">
          <div class="about-us-contents-left-a">
            <img src={Image7} alt="hwgh" />
          </div>

          <div class="about-us-contents-right-a">
            <div class="notes-a">
              <p style={{ fontSize: "1.4rem" }}>
                Kindness engagements are projects & events, both onsite &
                virtual, taken up by Kindness Unlimited.
              </p>

              <p style={{ fontSize: "1.4rem" }}>
                These engagements are mostly youth-centric that work in
                collaboration & with the support of kind individuals,
                corporations & high net worth individuals.
              </p>

              <p style={{ fontSize: "1.4rem" }}>
                In fact, through our various kindness engagements, we try to get
                closer to meeting the following 5 of the 17 SDGs taken up by the
                UN:
              </p>
            </div>
          </div>
        </div>
        <section class="mission-vision-a">
          <div class="img-grid">
            <div class="box-grid-diff-a ">
              <div class="box-content-diff-a">
                <div class="box-details-diff-a">
                  <div class="box-details-image-a">
                    {" "}
                    <img src={hunger} alt="hunger" class="image-pic" />
                  </div>
                  <div class="box-details-text-diff-a">Zero Hunger</div>
                </div>
              </div>
              <div class="box-content-diff-a">
                <div class="box-details-diff-a">
                  <div class="box-details-image-a">
                    {" "}
                    <img src={goodheart} alt="star" class="image-pic" />
                  </div>
                  <div class="box-details-text-diff-a">
                    Good Health & Wellbeing
                  </div>
                </div>
              </div>
              <div class="box-content-diff-a">
                <div class="box-details-diff-a">
                  <div class="box-details-image-a">
                    {" "}
                    <img src={scale} alt="star" class="image-pic" />
                  </div>
                  <div class="box-details-text-diff-a">
                    Reduced Inequalities
                  </div>
                </div>
              </div>
              <div class="box-content-diff-a">
                <div class="box-details-diff-a">
                  <div class="box-details-image-a">
                    {" "}
                    <img src={peace} alt="star" class="image-pic" />
                  </div>
                  <div class="box-details-text-diff-a">
                    Peace, Justice, and Strong Institutions
                  </div>
                </div>
              </div>
              <div class="box-content-diff-a">
                <div class="box-details-diff-a">
                  <div class="box-details-image-a">
                    {" "}
                    <img src={gender} alt="star" class="image-pic" />
                  </div>
                  <div class="box-details-text-diff-a">Gender Equality</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <p style={{ fontSize: "1.3rem" }}>
          Team KU ensures that these kindness engagements fall in line with KU’s
          mission of:
        </p>
        <div class="content-a cont-head" style={{ fontSize: "29px" }}>
          Connecting & unifying India by having people perpetually initiate acts
          of kindness.
        </div>
      </div>
    </div>
  );
};

export default Introduction;
