import React from "react";
import "./Newsroom.css";
import newsroomimg from "../../../images/media/1.png";
import { Link } from "react-router-dom";


const Newsroom = () => {
  return (
    <section class="newsroom">
      <div class="row">
        <div class="col-lg-6">
          <div class="card project-card mx-auto">
            <img
              class="card-img-top img-fluid"
              src={newsroomimg}
              alt="plantkindness"
            />
            <div class="card-block p-3">
              <h4 class="card-title our-projects-heading">Newsroom</h4>
              <p class="card-text project-name-body">
                Kindness Unlimited has been fortunate to be noticed for its
                relentless work in promoting and upholding kindness as a value.
                Some of the major coverages are available in this section that
                will give you an insight about us. If you'd like to know more or
                write about us, please contact us at
                contactus@kindnessunlimited.in
              </p>
              
              <div class="card-text row project-name-btns">
                <div class="col read-more-btn">
                  <Link to="/newsroom">
                  <button type="button mx-auto" class="btn btn-outline-dark">
                    {" "}
                    Read More{" "}
                  </button>{" "}
                  </Link>
                </div>{" "}
                <div class="col donate-btn">
                <Link to="/donate">
                  <button type="button" class="btn btn-outline-dark">
                    Donate
                  </button>
                  </Link>
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </div>

        <div class="col-lg-6 ">
          <div class="col">
            <div class="row youtube-embeds">
              <iframe
                height="330"
                src="https://www.youtube.com/embed/-3fUNnLb7WU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div class="row">
              <iframe
                height="330"
                src="https://www.youtube.com/embed/ZbKKCG-VUig"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsroom;
