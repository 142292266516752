import React from "react";
import { Link } from "react-router-dom";
import "./Projects.css";
import Image1 from "../../../images/Image1.jpg";
import Image7 from "../../../images/Image7.jpeg";
import hwgh from "../../../images/Howwegothere.jpeg";
import Image8 from "../../../images/Image8.jpeg";
import Image5 from "../../../images/Image5.jpg";
import ComNutrition from "../ComNutrition/ComNutrition.js";

const Projects = () => {
  return (
    <div class="engagments-grid">
      <div class="notes-a">
        <p style={{ fontSize: "1.4rem" }}>
          Kindness engagements are thoughtfully segregated into the following 5
          major categories:
        </p>
      </div>
      <section class="accommodation">
        <div class="stay">
          <Link to="./nutrition" class="stay-content">
            <img src={Image1} alt="Image1" />

            <div class="stay-details">
              <div class="stay-details-name">Community & Nutrition</div>
            </div>
          </Link>

          <Link to="./education" class="stay-content">
            <img src={hwgh} alt="hwgh" />

            <div class="stay-details">
              <div class="stay-details-name">Education</div>
            </div>
          </Link>

          <Link to="./arts" class="stay-content">
            <img src={Image7} alt="Image7" />

            <div class="stay-details">
              <div class="stay-details-name">Arts</div>
            </div>
          </Link>

          <Link to="./hangouts" class="stay-content">
            <img src={Image5} alt="Image5" />

            <div class="stay-details">
              <div class="stay-details-name"> Kindness Hangouts</div>
            </div>
          </Link>

          <Link to="./WKW" class="stay-content">
            <img src={Image8} alt="Image8" />

            <div class="stay-details">
              <div class="stay-details-name">World Kindness Week</div>
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Projects;
