import React, { useEffect } from "react";
import "./OurStory.css";
import Image1 from "../../images/Image1.jpg";
import { Link } from "react-router-dom";

const OurStory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="our-story">
      <section class="story">
        <h1 class="story-heading">Our Story</h1>
        <div class="story-grid">
          <div class="content">
            <div class="iframe-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/9_7b4UoioL4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <p class="text">
              In the mid-2000s, Late Mr Vasant Kalbag, a Mumbai based retired
              businessman; carried thorough research in the philosophy &
              practical application of being kind. Mr Kalbag, who was also a
              scientist by temperament, concluded his research- ‘Why Kindness
              Matters’, by stating that human beings show a tendency to give &
              take.
              <p class="text">
                <br />
                According to him; this sense of strong reciprocity could be
                taken advantage of, by consciously and deliberately doing random
                acts of kindness, as often as possible.
              </p>
              <p class="text">
                In fact, Darwin’s theory of evolution strongly states that
                cooperation & collaboration are, perhaps the 2 essential pillars
                that help humans innovate & prosper. Moreover, recent studies
                suggest that qualities such as cooperation, compassion & care,
                majorly help in making the human species successful.
              </p>
            </p>
          </div>
        </div>
      </section>
      <section class="logo">
        <h1 class="logo-heading">Behind Our Logo</h1>
        
          <div class="video-container1">
            
           
            <iframe
            class="video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/BTtYaDbs0Bk"
            title="Behind Our Logo - Kindness Unlimited"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
           
            
          </div>
      </section>
      <div class="text text-color">
        Thus, with this understanding in mind; Mr Vasant & Mrs Shanti Kalbag
        along with Mrs Nirmala Peters Mehendale, former HR professional &
        currently the founder trustee, formalised Kindness Unlimited in the year
        2005.
      </div>
      <div class="content-1 row">
        <div class="col-md-6 col-sm-12">
          <p class="text">
            KU started as a movement to inspire individuals, organisations &
            communities to help spread kindness by sharing their privileges. The
            founders believed that nothing could be more unkind than hoarding
            one’s privileges when so many others around were in misery!
          </p>
          <p class="text">
            Having said that, KU’s approach towards practising kindness rests on
            two important pillars; namely, self-interest + common good, which
            dictates the sensitive equation between the giver and the receiver.
          </p>
        </div>
        <div class="col-md-6 col-sm-12 video-container1">
          <iframe
          class="video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Floq0FkcEEs"
            title="Worldwide Dance for Kindness- Mumbai 2013"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div class="text text-color">
        Kindness begins by taking self-care before caring for others.
      </div>
      <div class="content-1 row">
      <div class="col-md-6 col-sm-12 video-container1">
          <iframe
          class="video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/9cMvQSC3dgo"
            title="Spread Kindness - it's Contagious"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      <div class="col-md-6 col-sm-12">

        <p class="text">
          We at KU, strongly believe that in order to avoid overwhelm &
          exhaustion; a healthy balance must be maintained between being kind to
          oneself and others. Any disbalance in either of the pillars,
          unfortunately, leads to selfishness & vengeance!
        </p>
        <p class="text">
          With these kindness engagements, we aim to leave a legacy where
          kindness is adopted as a sustainable habit & not merely as a pastime
          of the privileged.
        </p>
        
        <p class="text">
          In these last 16 years, we’ve successfully initiated, organised &
          supported multiple causes, events & projects that have had a snowball
          effect of kindness upon the beneficiaries.
        </p>
        <p class="text">
          Our continuous efforts to unify & strengthen diversity with the power
          of kindness have provided us with the opportunity to represent the
          ‘World Kindness Movement’ in India.
        </p></div>

</div>

      
      <div class="text text-color">
        Today, KU is an executive member of the World Kindness Movement, a
        global body for oneness, with representations from 28 countries across
        the world.
      </div>
      <div class="text text-color text-font">
        (The World Kindness Movement is the peak global body and international
        non-government organization with no religious or political affiliation.
        Its chief objective is to foster goodwill among the broad community –
        local, national and international – by way of kindness, and in so doing,
        create greater understanding and co-operation between all people and all
        nations throughout the world.)
      </div>
      <div class="content-1">
        <p class="text">
          Going forward, we aspire to strengthen this network of kindness by
          transforming the global mindset of ‘ME to WE’.
        </p>
        <p class="text">
          In a world where being judgemental towards everyone else but us, is
          easy…
        </p>
      </div>
      <div class="content-1">
        <p class="text">
          We aim to bring this change by setting an example of spreading
          ‘kindness unlimited’ via having compassion, understanding, empathy &
          goodness.
        </p>
        <p class="text">
          KU aims to inspire people to awaken the inherent virtue of kindness in
          them, by using every unkind situation as an opportunity to practice
          empathy, understanding & KINDNESS UNLIMITED.
        </p>
      </div>
      <div class="text text-color">
        With KU, we dream to transform India into KINDia, a country where
        practising kindness is prudent, practical & sustainable.
      </div>
      <div class="about-1">
        <div class="content-a cont-head">Found our story inspiring?</div>
        <div class="about-us-contents-a">
          <div class="about-us-contents-right-a">
            <div class="notes-a notes-n">
              <p>Why not help us spread ‘kindness unlimited’?</p>

              <p>
                Here’s a list of Kindness Opportunities you could choose to
                start your very own kindness journey with
              </p>
              <Link to="/opportunities">
                <button class="button about-us-btn">
                  Explore Kindness Opportunities
                </button>
              </Link>
            </div>
          </div>

          <div class="about-us-contents-left-a">
            <img src={Image1} class="image-1" alt="hwgh" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
