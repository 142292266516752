import React from "react";
import "./Collaborations.css";
import connectofr from "../../images/sponsors/connectofr.png";
import DAN from "../../images/sponsors/DAN.png";
import django from "../../images/sponsors/django.png";
import Drona from "../../images/sponsors/Drona-Foundation.png";
import Holy from "../../images/sponsors/Holy-Cross-Kurla.png";
import Jamnabai from "../../images/sponsors/Jamnabai-Narsee-School-black.png";
import kc from "../../images/sponsors/kc-college-1.png";
import Kohinoor from "../../images/sponsors/Kohinoor-Business-School_1.png";
import Life_Vest_Inside_Logo_transparent from "../../images/sponsors/Life_Vest_Inside_Logo_transparent.png";
import Little from "../../images/sponsors/Little-Sisters-of-the-Poor.png";
import MAD from "../../images/sponsors/MAD_LOGO_11-4-17-1.png";
import mcubed from "../../images/sponsors/MCubed-Library.png";
import roti from "../../images/sponsors/Mumbai-Roti-Bank.png";
import NSPA from "../../images/sponsors/NSPA.png";
import parleshwar from "../../images/sponsors/parleshwar-1.png";
import rotary from "../../images/sponsors/Rotary-Club.png";
import schbang from "../../images/sponsors/schbang.png";
import smash from "../../images/sponsors/Smaaash.png";
import sparsha from "../../images/sponsors/Sparsha-Charitable-Trust_transparency.png";
import andrews from "../../images/sponsors/St.-Andrews-college.png";
import TitleWaves from "../../images/sponsors/Title-Waves.png";
import toiing from "../../images/sponsors/toiing.png";
import trilogy from "../../images/sponsors/Trilogy.png";
import villa from "../../images/sponsors/Villa-Teresa-school.png";
import sporting from "../../images/sponsors/WhatsApp-Image-2020-03-15-at-8.19.45-AM-1.png";
import projectMumbai from "../../images/sponsors/WhatsApp-Image-2021-02-15-at-22.54.58-1.png";
import society from "../../images/sponsors/WhatsApp-Image-2021-02-15-at-22.54.58-2.png";
import sneha from"../../images/Snehavardhini.png";
import sgi from"../../images/SGI.png";
import thyssenkrupp from"../../images/Thyssenkrupp.png";


const Collaborations = () => {
  return (
    <div class="collab-page">
      <div class="collab-heading"> Collaborations </div>

      {/* Project Partners */}
      <div class="collab-subheading">Project Partners</div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={rotary} height="150" width="150" alt="" />
          <div class="partners-name text">Rotary Club</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={society} height="150" width="150" alt="" />
          <div class="partners-name text">Society Tea</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img
            src={Life_Vest_Inside_Logo_transparent}
            height="150"
            width="150"
            alt=""
          />
          <div class="partners-name text">Life Vest Inside</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={roti} height="150" width="150" alt="" />
          <div class="partners-name text">Roti Bank</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={connectofr} height="150" width="150" alt="" />
          <div class="partners-name text">ConnectFor</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={NSPA} height="150" width="150" alt="" />
          <div class="partners-name text">NSPA</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={smash} height="150" width="150" alt="" />
          <div class="partners-name text">Smaaash</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={toiing} height="150" width="150" alt="" />
          <div class="partners-name text">Toiing</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={sneha} height="150" width="150" alt="" />
          <div class="partners-name text">Snehavardhini Social Trust</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={sgi} height="150" width="150" alt="" />
          <div class="partners-name text">Sustainable Green Initiative</div>
        </div>


        <div class="collab-subheading">CSR Partners</div>
        <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
          <img src={thyssenkrupp} height="150" width="150" alt="" />
          <div class="partners-name text">Thyssenkrupp</div>
        </div>
          </div>
        
        {/* NGO partners */}
        <div class="collab-subheading">NGO Partners</div>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={parleshwar} height="150" width="150" alt="" />
            <div class="partners-name text">Rotary Club of Mumbai</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={projectMumbai} height="150" width="150" alt="" />
            <div class="partners-name text">Project Mumbai</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={sporting} height="150" width="150" alt="" />
            <div class="partners-name text">Sporting Lions</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={sparsha} height="150" width="150" alt="" />
            <div class="partners-name text">Sparsha Charitable Trust</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={Drona} height="150" width="150" alt="" />
            <div class="partners-name text">Drone Foundation</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={Little} height="150" width="150" alt="" />
            <div class="partners-name text">Home for the Aged</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={MAD} height="150" width="150" alt="" />
            <div class="partners-name text">Make a Difference</div>
          </div>
          
        </div>


        {/* Digital partners  */}

        <div class="collab-subheading">Digital Partners</div>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={schbang} height="150" width="150" alt="" />
            <div class="partners-name text">Schbang</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={django} height="150" width="150" alt="" />
            <div class="partners-name text">Django Digital</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={DAN} height="150" width="150" alt="" />
            <div class="partners-name text">Dentsu Aegis Network</div>
          </div>

          {/* Venue partners */}
          <div class="collab-subheading">Venue Partners</div>
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
              <img src={mcubed} height="150" width="150" alt="" />
              <div class="partners-name text">Mcubed Library</div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
              <img src={TitleWaves} height="150" width="150" alt="" />
              <div class="partners-name text">Title Waves</div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
              <img src={trilogy} height="150" width="150" alt="" />
              <div class="partners-name text">Trilogy</div>
            </div>
          </div>

          {/* Education Partners */}
          <div class="collab-subheading">Education Partners</div>
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={villa} height="150" width="150" alt="" />
            <div class="partners-name text">Villa Theresa High School</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={Holy} height="150" width="150" alt="" />
            <div class="partners-name text">Holy Cross High School, Kurla</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={andrews} height="150" width="150" alt="" />
            <div class="partners-name text">St. Andrews College, Bandra</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={Kohinoor} height="150" width="150" alt="" />
            <div class="partners-name text">Kohinoor Business School</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={Jamnabai} height="150" width="150" alt="" />
            <div class="partners-name text">Jamnabai Narsee School</div>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-6 partner-logos">
            <img src={kc} height="150" width="150" alt="" />
            <div class="partners-name text">KC College</div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Collaborations;
