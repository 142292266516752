import React from "react";
import "./ASection1.css";
import Image5 from "../../../images/Image5.jpg";
import { Link } from "react-router-dom";


const ASection1 = () => {
  return (
    <div class="about-1">
        <div class="content-a cont-head">
          Does being kind leave you feeling tired, insecure or regretful?
        </div>
        <div class="about-us-contents-a">
          <div class="about-us-contents-left-a">
          <img src={Image5} alt="hwgh"/>
          </div>

          <div class="about-us-contents-right-a">
          <div class="notes-a">
            <p>
            Imagine this, it’s 6 O’clock in the evening & you’re all set to leave
            for home. On your way to the door, you exchange a goodbye with the
            teammate who’s working overtime, when suddenly, he stops you to ask
            for quick help.
            </p>

            <p>
            You’re tired, your children are expecting you to get back home early &
            tomorrow’s gonna be an equally busy workday for you. But, you don’t
            want to come across as a selfish teammate, so you accept his request.
            </p>

            <p>
            10 minutes into it, and this colleague gets a call to reach home ASAP.
            Unexpected guests seem to have arrived at his place! Helpless, he
            requests you to cover for him. <br />
            What do you do next?
            </p>

            <p>
            You get overwhelmed with the borrowed workload, resentment for your
            teammate crops in & you realise that perhaps kindness is for fools!
            </p>

            <div class="content-a cont-head">That’s where we come in!</div>

            <p>
            Kindness Unlimited helps you spread kindness by giving you a practical
            approach to being kind without having to feel overwhelmed.
            </p>
            <Link to="/contactus">
            <button class="button about-us-btn">
              I’d like to start my kindness journey
            </button>
            </Link>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ASection1;
