import React, { useEffect } from "react";
import "./Arts.css";
import Zumba from "../../../images/zumba.jpg";
import Musical from "../../../images/musical.jpg";
import KUJam from "../../../images/KindnessJam.jpg";
import Beats from "../../../images/BeatsKU.jpg";
import MusicNSPA from "../../../images/KUMusic-nspa.jpg";
import Dance from "../../../images/danceKU.jpg";

import { Link } from "react-router-dom";

const Arts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="arts">
        <div class="arts-heading">
          KU for Arts
        </div>
        
      <div class="center-part">
       
        <section class="accommodation">
          <div class="stay">
            <Link to="././musical" class="stay-content">
              <img src={Musical} alt="Image2" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Musical for Fund-Raising{" "}
                </div>
              </div>
            </Link>

            <Link to="././zumba" class="stay-content">
              <img src={Zumba} alt="hwgh" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Zumba Session for Wheelchair Bound{" "}
                </div>
              </div>
            </Link>
         
            <Link to="././kindnessjam" class="stay-content">
              <img src={KUJam} alt="Image4" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  The Kindness Jam{" "}
                </div>
              </div>
            </Link>

            <Link to="././beats" class="stay-content">
              <img src={Beats} alt="hwgh" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Beats of a Kind{" "}
                </div>
              </div>
            </Link>
            <Link to="././kindnessmusic" class="stay-content">
              <img src={MusicNSPA} alt="hwgh" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Kindness in Music - NSPA
                </div>
              </div>
            </Link>
            <Link to="././dancekindness" class="stay-content">
              <img src={Dance} alt="hwgh" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Dance for Kindness{" "}
                </div>
              </div>
            </Link>
          </div>
        </section>
      </div>
      </div>
    </>
  );
};

export default Arts;
