import React, { useEffect }  from "react";
import "./KindnessJam.css";

import kj1 from "../../../images/kj1.jpg";
import kj2 from "../../../images/kj2.jpg";
import kj3 from "../../../images/kj3.jpg";
import kj4 from "../../../images/kj4.jpg";
import kj5 from "../../../images/kj5.jpg";
import kj6 from "../../../images/kj6.jpg";
import kj7 from "../../../images/kj7.jpg";
import kj8 from "../../../images/kj8.jpg";
import kj9 from "../../../images/kj9.jpg";
import kj10 from "../../../images/kj10.jpg";

const KindnessJam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="kindness-jam">
      <div class="center-part">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
          Kindness Jam
        </div>

        <div class="story-grid">
          <div class="content">
            <div class="video-container">
              <iframe
                height="549"
                src="https://www.youtube.com/embed/zu00cK6ITek"
                title="The Kindness Jam 2019"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>{" "}
            </div>
            <p class="kindness-jam-content text">
              To celebrate World Kindness Day in 2019, we put together our
              largest event at St.Andrews Auditorium that had performances by
              Ankur Tewari, the music Supervisor for Gully Boy, ONEmpire – the
              pop rock band and Aflatunes, the incredible acapella group.
              However to get tickets for the show, each person had to carry out
              an act of kindness. We organized volunteering events with
              Connect.for which you could attend and get a ticket. That’s it. No
              cash, no credit, only kindness!
            </p>
          </div>
        </div>
      </div>
      <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={kj1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj6} />
            </div>
          </div>
          <div class="carousel-item bg-7">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj7} />
            </div>
          </div>
          <div class="carousel-item bg-8">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj8} />
            </div>
          </div>
          <div class="carousel-item bg-9">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj9} />
            </div>
          </div>
          <div class="carousel-item bg-10">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kj10} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj8} />
          </button>
          <button
            aria-label="Slide 9"
            data-bs-slide-to="8"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj9} />
          </button>
          <button
            aria-label="Slide 10"
            data-bs-slide-to="9"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kj10} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default KindnessJam;
