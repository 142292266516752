import React, { useEffect }  from "react";

const FoodDistribution = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="about-us">
      <div class="center-part">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
          Food Distribution
        </div>

        <div class="story-grid">
          <div class="content">
            <div class="iframe-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/DQdHnOwMDQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p class="text">
              Kindness engagements were started with the sole aim of sharing our
              privileges with the downtrodden, along with creating a snowball
              effect of kindness that’ll eventually help us in our mission of
              having people perpetually initiate acts of kindness.
              <br />
              Therefore, with this very idea in mind, we decided to spend World
              Kindness Day ‘18, with the future of our nation- the children.
              <p class="text">
                <br />
                KU in association with Schbang & Roti Bank, celebrated World
                Kindness Day- 2018, with 20,000...yes, 20,000 children across
                BMC schools, Mumbai (India).
              </p>
              <p class="text">
                We distributed 20,000 edible gifts of kindness to the children
                with the hope that the kindness bug hits them soon! We played,
                we laughed, we danced & made interesting conversations around
                practising kindness.
                <br />
                After all, the motive was never to distribute food alone but to
                make the lil’ ones experience the true joy of being kind.
              </p>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoodDistribution;
