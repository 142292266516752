import React from "react";
import "./Internships.css";
import khushi from "../../../images/KhushiGupta.png";
import Shantanu from "../../../images/ShantanuDeshpande.jpg";

const Internships = () => {
  return (
    <section class="internships">
      <h3 class="heading-internships">Internships</h3>
      <h5 class="sub-heading-internships">Opportunities (Click on a domain for details)</h5>

      <div class="box-internships">
        <div class="intern-box">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">
          <div class="intern-name">Social Media</div>
          <div class="intern-sub">
            {" "}
           Content Writing, Graphic designing & Engagement
          </div>
          </a>
        </div>
     
        <div class="intern-box">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">

          <div class="intern-name">Website </div>
          <div class="intern-sub"> Development, Design & Management</div>
          </a>
        </div>
        <div class="intern-box">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">

          <div class="intern-name"> Organisation Building</div>
          <div class="intern-sub"> Operations, Planning & Management</div>
          </a>
        </div>
        <div class="intern-box">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">

          <div class="intern-name">Fundraising</div>
          <div class="intern-sub"> Grants, Crowdfunding & Corpus Funds</div>
          </a>
        </div>
        <div class="intern-box">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">

          <div class="intern-name"> Communications</div>
          <div class="intern-sub">News & Media</div>
          </a>
        </div>
        <div class="intern-box">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhucOya_z8zfgTBbcIsxcY012Nj6Mn9y0xbuc90j89j32x_w/viewform">

          <div class="intern-name">Relationship building</div>
          <div class="intern-sub">
            {" "}
            Individuals, Corporations & High Net Worth Individual
          </div>
          </a>
        </div>
      </div>
      <h5 class="sub-heading-internships">Testimonals</h5>
      <div class="feedback-int">
        <div class="feedback-person-int">
          <div class="feedback-text-int">
            <p>
              I was a part of the ‘Kindness Act Collecting’ internship by KU.
              The weekly workshops were so enriching. Also, team KU & my
              co-interns were extremely supportive & great to work with! My
              faith in kindness has increased manifolds after joining KU & I
              look forward to working with KU soon again.
            </p>
          </div>
          <div class="feedback-person-details-int">
            <img class="testimonial-img"  src={khushi} alt="person-1" />
            <div class="user-details-int">
              <div class="user-name-int">Khushi</div>
              <div class="user-place-int"> Volunteer, 1MillionStory Campaign</div>
            </div>
          </div>
        </div>

        <div class="feedback-person-int">
          <div class="feedback-text-int">
            <p>
              Ku gave me the platform to influence people to BE kind. It also
              helped me gain a new perspective towards being kind. I’ve realised
              the power of kindness & that kindness perhaps is a strength & not
              a weakness! The satisfaction of having played a small part in this
              journey of kindness gives me immense joy & fills me with
              gratitude.
            </p>
          </div>
          <div class="feedback-person-details-int">
            <img class="testimonial-img" src={Shantanu} alt="person-1" />
            <div class="user-details-int">
              <div class="user-name-int">Shantanu Deshpande</div>
              <div class="user-place-int"> Volunteer, 1MillionStory Campaign</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Internships;
