import React, { useEffect } from "react";
import "./Education.css";
import Image6 from "../../../images/DonateAToi.jpg";
import Image2 from "../../../images/KCCollegeEvent.jpg";
import Image5 from "../../../images/KohinoorBS.jpg";
import Image4 from "../../../images/KindnessCircles.jpg";
import { Link } from "react-router-dom";

const Education = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div class="education">
      <div class="education-heading">
        KU for Education
      </div>
      <div class="center-part-education">
        <section class="accommodation">
          <div class="stay">
            <Link to="././KCCollege" class="stay-content">
              <img class="education-photo" src={Image2} alt="Image2" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  KC College Event
                </div>
              </div>
            </Link>

            <Link to="././kohinoor" class="stay-content">
              <img class="education-photo" src={Image5} alt="hwgh" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Kohinoor Business School
                </div>
              </div>
            </Link>
          </div>
        </section>
      </div>

      <div class="center-part">
        <div class="notes-a">
          <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>Projects</p>
        </div>
        <section class="accommodation">
          <div class="stay">
            <Link to="././kindnesscircles" class="stay-content">
              <img class="education-photo" src={Image4} alt="Image4" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Kindness Circles
                </div>
              </div>
            </Link>

            <Link to="././donateatoi" class="stay-content">
              <img class="education-photo" src={Image6} alt="hwgh" />

              <div class="stay-details">
                <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                  Donate-A-Toi
                </div>
              </div>
            </Link>
          </div>
        </section>
      </div>
      </div>
    </>
  );
};

export default Education;
