import React from "react";
import "./Navbar.css";
import logo from "./../../images/Headerlogo.png";
import { Link } from "react-router-dom";
import { NavLinks, NavLogo } from "./NavbarElements";

const Navbar = () => {
  return (
    <div>
      <nav class="navbar navbar-light fixed-top navbar-expand-lg">
        <div class="container-fluid">
          <NavLogo class="navbar-brand" to="/">
            <img
              class="ku-logo-navbar"
              src={logo}
              alt=""
              width="80"
              height="69"
            />
          </NavLogo>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav ">
              <li class="nav-item dropdown">
                <NavLinks
                  class="nav-link dropdown-toggle"
                  to="/"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Us
                </NavLinks>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <NavLinks to="/wwd"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">What We Do</li></NavLinks>
                  <NavLinks to="/ourstory"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Our Story</li></NavLinks>
                  <NavLinks to="/wkm"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">World Kindness Movement</li></NavLinks>
                  <NavLinks to="/ourteam"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Our Team</li></NavLinks>
                </ul>
              </li>
              <li class="nav-item">
                <NavLinks to="/engagements">
                <li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                Kindness Engagements
                </li>
                </NavLinks>
              </li>
              <li class="nav-item dropdown">
                <NavLinks
                  class="nav-link dropdown-toggle"
                  to="/"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  KU Opportunities
                </NavLinks>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <NavLinks to="/syt"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Share Your Talents</li></NavLinks>
                  <NavLinks to="/opportunities"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Opportunities</li></NavLinks>
                  <NavLinks to="/collaborations"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Collaborations</li></NavLinks>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <NavLinks
                  class="nav-link dropdown-toggle"
                  to="/"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  KU Inspirations
                </NavLinks>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <NavLinks to="/gallery"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Gallery</li></NavLinks>
                  <NavLinks to="/newsroom"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Newsroom</li></NavLinks>
                </ul>
              </li>
              <li class="nav-item">
                <NavLinks to="/contactus"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact Us</li></NavLinks>
              </li>
              {/*<li class="nav-item">
                <NavLinks to="/project-kul"><li data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Project - KUL</li></NavLinks>
              </li>*/}
              <li  class="nav-item text-center">
                <NavLinks to="/donate" class="donatehome-btn"><li  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                  <button class="button kindness-btn">Donate</button></li>
                </NavLinks>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;