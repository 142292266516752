import React, { useEffect } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="privacy-policy">
      <div class="privacy-heading">Privacy Policy</div>
      <div class="privacy-contents">
        <div class="privacy-subheading">Who we are</div>
        <p class="privacy-text">
          Our website address is: http://kindnessunlimited.in/.
        </p>
        <div class="privacy-subheading">
          What personal data we collect and why we collect it
        </div>
        <p class="privacy-text">
          We collect email address, phone numbers and the names of individuals
          based on whether they contact us via the Contact Us page or if they
          are applying for an Internship via the Internship Programme page.
          Collection is necessary so we can maintain and establish contact with
          our visitors and applicants. We only collect data sent by the users
          themselves.
        </p>
        <div class="privacy-subheading2">Media</div>
        <p class="privacy-text">
          If you upload images to the website, you should avoid uploading images
          with embedded location data (EXIF GPS) included. Visitors to the
          website can download and extract any location data from images on the
          website.
        </p>
        <div class="privacy-subheading2">Contact forms</div>
        <p class="privacy-text">
          We use contact forms to collect feedback and information regarding
          internships. We collect names, email and phone numbers from the forms
          to establish contact with the applicants. We do not use personal
          information for any marketing related businesses.
        </p>
        <div class="privacy-subheading2">Cookies</div>
        <p class="privacy-text">
          If you leave a comment on our site you may opt-in to saving your name,
          email address and website in cookies. These are for your convenience
          so that you do not have to fill in your details again when you leave
          another comment. These cookies will last for one year.
          <br/><br/> If you visit
          our login page, we will set a temporary cookie to determine if your
          browser accepts cookies. This cookie contains no personal data and is
          discarded when you close your browser.
          <br/><br/>
           When you log in, we will also
          set up several cookies to save your login information and your screen
          display choices. Login cookies last for two days, and screen options
          cookies last for a year. If you select “Remember Me”, your login will
          persist for two weeks. If you log out of your account, the login
          cookies will be removed.
          <br/><br/> If you edit or publish an article, an
          additional cookie will be saved in your browser. This cookie includes
          no personal data and simply indicates the post ID of the article you
          just edited. It expires after 1 day.
        </p>
        <div class="privacy-subheading2">
          Embedded content from other websites
        </div>
        <p class="privacy-text">
          Articles on this site may include embedded content (e.g. videos,
          images, articles, etc.). Embedded content from other websites behaves
          in the exact same way as if the visitor has visited the other website.
          <br/><br/>
          These websites may collect data about you, use cookies, embed
          additional third-party tracking, and monitor your interaction with
          that embedded content, including tracking your interaction with the
          embedded content if you have an account and are logged in to that
          website.
        </p>
        <div class="privacy-subheading">How long we retain your data</div>
        <p class="privacy-text">
          If you leave a comment, the comment and its metadata are retained
          indefinitely. This is so we can recognize and approve any follow-up
          comments automatically instead of holding them in a moderation queue.
          <br/><br/>
          For users that register on our website (if any), we also store the
          personal information they provide in their user profile. All users can
          see, edit, or delete their personal information at any time (except
          they cannot change their username). Website administrators can also
          see and edit that information.
        </p>
        <div class="privacy-subheading">What rights you have over your data
</div>
        <p class="privacy-text">
        If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.

</p>
        <div class="privacy-subheading">Where we send your data
</div>
        <p class="privacy-text">
        Visitor comments and forms may be checked through an automated spam detection service.

</p>
        <div class="privacy-subheading">Contact Information
</div>
        <p class="privacy-text">
        Mail: contactus@kindnessunlimited.in
        <br/><br/>

Phone: 9820710081        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
