import React, { useEffect }  from "react";
import "./KCCollege.css";
import kcevent1 from "../../../images/kcevent1.jpg";
import kcevent2 from "../../../images/kcevent2.jpg";
import kcevent3 from "../../../images/kcevent3.jpg";
import kcevent4 from "../../../images/kcevent4.jpg";
import kcevent5 from "../../../images/kcevent5.jpg";
import kcevent6 from "../../../images/kcevent6.jpg";


const KCCollege = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section class="KCCollege">
      <div class="center-part">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
          KC College Event
        </div>

        <div class="story-grid">
            <p class="text">
              On the 13th of Feb, Kindness Unlimited in association with KC
              College’s intercollegiate festival- Joule, organised a fantastic
              event that brought together a bunch of less fortunate street
              children and a set of competing teams from various colleges across
              the city. The event required both the college students and the
              children to work together in order to win. These college students
              didn’t simply invite these kids into their teams but into their
              hearts, a kindness for which there are no words.
            </p>
         
        </div>
        <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={kcevent1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={kcevent6} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={kcevent6} />
          </button>
        </div>
      </div>
      </div>
    </section>
  );
};

export default KCCollege;
