import React from "react";
import "./Query.css";
import person from "../../../images/person-1.png";
import { Link } from "react-router-dom";


const Query = () => {
  return (
    <div class="query-1">
      <div class="notes-a">
        <p>
          {" "}
          Didn’t find what you were looking for? Or is that the areas of
          interest are not something you particularly specialise in?.
        </p>
        <p>Well, we don’t believe in disappointing interested folks</p>


        <p>More so if they’re interested in kindness!</p>

        <div class="content-a cont-head" style={{ paddingBottom: "10px" }}>
          So, here’s the good news: You could still join us in this journey of
          kindness!
        </div>

        <p>
          Kindly fill in this <Link to="/contactus">form</Link> to help us know more about how you’d like to
          help KU accelerate its journey of kindness. <br />
          We’ve kept it simple, short & direct.
        </p>
        <p>
          Rest assured, we will get back to you within 24 hrs. of receiving the
          form.
        </p>
        
            <div class="quote-name">
              ‘Life’s most urgent and persistent question is, What are you doing
              for others?’
            </div>
            <div class="quote-sub">Martin Luther King, Jr.</div>
          
      </div>
    </div>
  );
};

export default Query;
