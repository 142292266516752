import React, { useEffect }  from "react";
import "./Beats.css";
import beats1 from "../../../images/beats1.jpg";
import beats2 from "../../../images/beats2.jpg";
import beats3 from "../../../images/beats3.jpg";
import beats4 from "../../../images/beats4.jpg";
import beats5 from "../../../images/beats5.jpg";
import beats6 from "../../../images/beats6.jpg";
import beats7 from "../../../images/beats7.jpg";
import beats8 from "../../../images/beats8.jpg";
import beats9 from "../../../images/beats9.jpg";
import beats10 from "../../../images/beats10.jpg";
import beats11 from "../../../images/beats11.jpg";
import beats12 from "../../../images/beats12.jpg";
import beats13 from "../../../images/beats13.jpg";
import beats14 from "../../../images/beats14.jpg";

const Beats = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="beats">
      <div class="col">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
          Beats of a Kind
        </div>
        <p class="text">
          Music has an amazing ability to empower, inspire, captivate and rally
          people together for something greater than themselves. Just as music
          is a universal language, so is Kindness and it is with the
          transformative power of music that we wanted to kick off World
          Kindness Week, we presented an event at the Carter Road Promenade
          which aimed to bring together an ensemble of music artists, each doing
          performances that were socially geared and celebrated the human
          spirit. Our music collaborators for this included – True School of
          Music, NSPA, and acclaimed Indie pop artists- Alisha Pais and Tejas
          Menon
        </p>
        <div
          class="carousel slide"
          data-bs-ride="carousel"
          id="carouselExampleCaptions"
        >
          <div class="carousel-inner">
            <div class="carousel-item active bg-1">
              <div class="img-container-carousel d-flex">
                <img class="img-main" src={beats1} />
              </div>
            </div>
            <div class="carousel-item bg-2">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats2} />
              </div>
            </div>
            <div class="carousel-item bg-3">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats3} />
              </div>
            </div>
            <div class="carousel-item bg-4">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats4} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats5} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats6} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats7} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats8} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats9} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats10} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats11} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats12} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats13} />
              </div>
            </div>
            <div class="carousel-item bg-5">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={beats14} />
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            data-bs-slide="prev"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            data-bs-slide="next"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
            <span class="visually-hidden">Next</span>
          </button>

          <div class="carousel-indicators">
            <button
              aria-label="Slide 1"
              class="active"
              data-bs-slide-to="0"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats1} />
            </button>{" "}
            <button
              aria-label="Slide 2"
              data-bs-slide-to="1"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats2} />
            </button>{" "}
            <button
              aria-label="Slide 3"
              data-bs-slide-to="2"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats3} />
            </button>
            <button
              aria-label="Slide 4"
              data-bs-slide-to="3"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats4} />
            </button>
            <button
              aria-label="Slide 5"
              data-bs-slide-to="4"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats5} />
            </button>
            <button
              aria-label="Slide 6"
              data-bs-slide-to="5"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats6} />
            </button>
            <button
              aria-label="Slide 7"
              data-bs-slide-to="6"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats7} />
            </button>
            <button
              aria-label="Slide 8"
              data-bs-slide-to="7"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats8} />
            </button>
            <button
              aria-label="Slide 9"
              data-bs-slide-to="8"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats9} />
            </button>
            <button
              aria-label="Slide 10"
              data-bs-slide-to="9"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats10} />
            </button>
            <button
              aria-label="Slide 11"
              data-bs-slide-to="10"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats11} />
            </button>
            <button
              aria-label="Slide 12"
              data-bs-slide-to="11"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats12} />
            </button>
            <button
              aria-label="Slide 13"
              data-bs-slide-to="12"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats13} />
            </button>
            <button
              aria-label="Slide 14"
              data-bs-slide-to="13"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={beats14} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beats;
