import React, { useEffect }  from "react";
import "./Nashta.css";
import Image1 from "../../../images/nashta1.jpg";
import { Link } from "react-router-dom";


const Nashta = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="nashta">
    <div class="about-1">
      <div class="content-a cont-head">Project Nashta</div>
      <div class="about-us-contents-a">
        <div class="about-us-contents-left-a">
          <img src={Image1} alt="hwgh" />
        </div>

        <div class="about-us-contents-right-a">
          <div class="notes-a notes-a-o">
            <p>
              Kindness Unlimited has launched Project Nashta in April 2021 to
              address the nutrition needs of underprivileged children from
              Adivasi areas as well as the urban poor.
            </p>

            <p>
              During the ongoing Covid19 pandemic daily wage workers have lost
              jobs leaving many children extremely undernourished. Join us and
              be a part of this endeavor to reach out to vulnerable children.
            </p>

            <p>
              Kindness Unlimited is a 80G registered trust. Contributions can be
              made in Kind too please reach us at contactus@kindnessunlimited.in
            </p>
            <Link to="/donate">
            <button class="button donatehome-btn ">DONATE</button></Link>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Nashta;
