import React, { useEffect }  from "react";
import ContactUs from "./ContactUs/ContactUs";
import FAQs from "./FAQs/FAQs";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <FAQs/>
    <ContactUs/>
    </>
  );
};

export default Contact;
