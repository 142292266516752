import React, { useEffect }  from "react";
import "./Kohinoor.css";
import Kohinoor1 from "../../../images/KBS1.jpg";
import Kohinoor2 from "../../../images/KBS2.jpg";
import Kohinoor3 from "../../../images/KBS3.jpg";
import Kohinoor4 from "../../../images/KBS4.jpg";
import Kohinoor5 from "../../../images/KBS5.jpg";
import Kohinoor6 from "../../../images/KBS6.jpg";
import Kohinoor7 from "../../../images/KBS7.jpg";
import Kohinoor8 from "../../../images/KBS8.jpg";
import Kohinoor9 from "../../../images/KBS9.jpg";
import Kohinoor10 from "../../../images/KBS10.jpg";

const Kohinoor = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="kohinoor">
      <div class="col">
        <div class="row kohinoor-heading">Kohinoor Business School</div>
        <div class="row kohinoor-content">
          We tied up with Business Schools to organise a day of exchange between
          the business school students and a few underprivileged kids. Through
          the course of the day, we played games, danced to music and shared
          stories.
        </div>
        <div
        class="carousel slide"
        data-bs-ride="carousel"
        id="carouselExampleCaptions"
      >
        <div class="carousel-inner">
          <div class="carousel-item active bg-1">
            <div class="img-container-carousel d-flex">
              <img class="img-main" src={Kohinoor1} />
            </div>
          </div>
          <div class="carousel-item bg-2">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor2} />
            </div>
          </div>
          <div class="carousel-item bg-3">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor3} />
            </div>
          </div>
          <div class="carousel-item bg-4">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor4} />
            </div>
          </div>
          <div class="carousel-item bg-5">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor5} />
            </div>
          </div>
          <div class="carousel-item bg-6">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor6} />
            </div>
          </div>
          <div class="carousel-item bg-7">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor7} />
            </div>
          </div>
          <div class="carousel-item bg-8">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor8} />
            </div>
          </div>
          <div class="carousel-item bg-9">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor9} />
            </div>
          </div>
          <div class="carousel-item bg-10">
            <div class="img-container-carousel d-flex">
              <img class="img-main img-fluid" src={Kohinoor10} />
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          data-bs-slide="prev"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          data-bs-slide="next"
          data-bs-target="#carouselExampleCaptions"
          type="button"
        >
          <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
          <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-indicators">
          <button
            aria-label="Slide 1"
            class="active"
            data-bs-slide-to="0"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor1} />
          </button>{" "}
          <button
            aria-label="Slide 2"
            data-bs-slide-to="1"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor2} />
          </button>{" "}
          <button
            aria-label="Slide 3"
            data-bs-slide-to="2"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor3} />
          </button>
          <button
            aria-label="Slide 4"
            data-bs-slide-to="3"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor4} />
          </button>
          <button
            aria-label="Slide 5"
            data-bs-slide-to="4"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor5} />
          </button>
          <button
            aria-label="Slide 6"
            data-bs-slide-to="5"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor6} />
          </button>
          <button
            aria-label="Slide 7"
            data-bs-slide-to="6"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor7} />
          </button>
          <button
            aria-label="Slide 8"
            data-bs-slide-to="7"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor8} />
          </button>
          <button
            aria-label="Slide 9"
            data-bs-slide-to="8"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor9} />
          </button>
          <button
            aria-label="Slide 10"
            data-bs-slide-to="9"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <img class="img-thumb img-fluid" src={Kohinoor10} />
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Kohinoor;
