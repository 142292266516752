import React from "react";
import Footer from "../Footer/Footer";
import Differentsection from "./Differentsection/Differentsection";
import HeroSection from "./HeroSection/HeroSection";
import Howwegothere from "./Howwegothere/Howwegothere";
import MissionVision from "./MissionVision/MissionVision";
import Testimonial from "./Testimonial/Testimonial";
import Partners from "./Partners/Partners";
import NumberCounter from "./NumberCounter/NumberCounter"
import OurProjects from "./Ouprojects/OurProjects"
import Newsroom from "./Newsroom/Newsroom"

const Home = () => {
  return (
    <>
    <HeroSection/>
    <NumberCounter/>
    <Differentsection/>
    <Howwegothere/>
    <MissionVision/>
    <OurProjects/>
    <Partners/>
    <Newsroom/>
    <Testimonial/>
    </>
  );
};

export default Home;
