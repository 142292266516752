import React from "react";
import "./ASection2.css";
import keylogo from "../../../images/keylogo.png";
import Image3 from "../../../images/Image3.jpeg";

const ASection2 = () => {
  return (
    <div class="about-2">
      <div class="about-us-contents-b">
        <div class="about-us-contents-left-b">
          <div class="content-a cont-head">
            KU strongly believes that kindness was never about being selfless to
            the point of eventually becoming selfish.
          </div>
        </div>

        <div class="about-us-contents-right-b">
          <div class="notes-a">
            <img class="key-logo" src={Image3} alt="key" />
          </div>
        </div>
      </div>

      <div class="about-us-contents-b">
        <div class="about-us-contents-left-b">
          <img class="image-about" src={keylogo} alt="key" />
        </div>

        <div class="about-us-contents-right-b order">
          <div class="notes-a">
            <p>
              Replenishing one’s cup to the brim first so as to be able to fill
              others’ cups is, in fact, the correct approach towards ‘being
              kind’.
              <br />
              After all, being kind to oneself is perhaps, the first step in the
              journey of kindness.
            </p>
            <p>
              As a Guidestar Certified NGO & the only NGO to represent India at
              the ‘World Kindness Movement’- <br />
              KU uses the power of collaboration, youth & volunteerism to unify
              & eventually transform India into KINDia by having people initiate
              perpetual acts of kindness.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ASection2;
