import React from "react";
import "./Newsroom.css";
import BusinessCaseImg from "../../images/business-case.jpeg";
import SurvivalKindestImg from "../../images/SurvivalKindest.jpg";
import FirstImage from "../../images/media/1.png";
import SecondImage from "../../images/media/2.png";
import ThirdImage from "../../images/media/3.jpg";
import FourthImage from "../../images/media/4.jpg";
import FifthImage from "../../images/media/5.jpg";
import SixthImage from "../../images/media/6.jpg";
import SeventhImage from "../../images/media/7.jpg";
import EighthImage from "../../images/media/8.jpg";
import NinethImage from "../../images/media/9.jpg";
import TenthImage from "../../images/media/10.jpg";
import businessCase from "../../docs/IMA-Conclusions-Paper-The-Busines-Case-for-Kindness-November-2020.pdf";
import SurvivalKindest from "../../docs/Survival-of-the-Kindest-Article.pdf";

const Newsroom = () => {
  return (
    <div class="newsroompage">
      <div class="newsroom-heading"> Newsroom </div>{" "}
      <div class="col">
        <div class="row">
          <div class="col-md-3 business-case">
            <img
              class="business-case-image"
              src={BusinessCaseImg}
              alt="imagehero"
            />
          </div>{" "}
          <div class="col-md-9 business-case-info">
            <div class="col">
              <div class="row business-case-heading">
                {" "}
                The Business Case for Kindness{" "}
              </div>{" "}
              <div class="row business-case-body">
                Popular belief holds that being kind is a risk in the cut -
                throat corporate world, where performance and productivity are
                prized.However, social scientists argue that prosocial behaviour
                at the workplace heightens employee engagement, promotes brand
                loyalty and improves business performance in the long run.{" "}
                <br />
                <br />
                At a webinar of the IMA India CEO and CHRO Forums, Nirmala
                Mehendale, founder Trustee of Kindness Unlimited and Shailesh
                Haribhakti, one of the country’ s most prominent Chartered and
                Cost Accountants and an evangelist of the‘ kindness movement’,
                shared the principles of kindness in a post - Covid world and
                its impact on business performance.The paper summarises the
                thoughts shared.{" "}
              </div>{" "}
              <div class="row business-case-btn  mx-auto"><a href={businessCase}>
                <button type="button" class="btn btn-outline-dark">
                  {" "}
                  Download PDF{" "}
                </button>{" "}
                </a>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div class="row">
        <div class="row">
          <div class="col-md-3 business-case">
            <img
              class="business-case-image"
              src={SurvivalKindestImg}
              alt="imagehero"
            />
          </div>{" "}
          <div class="col-md-9 business-case-info">
            <div class="col">
              <div class="row business-case-heading">
                {" "}
                Survival of the Kindest{" "}
              </div>{" "}
              <div class="row business-case-body">
                In a world obsessed with cutthroat competition, is kindness a weakness? Flip the script, and reveal how kindness is actually key to business success and a happier world. Discover how fostering a culture of compassion can boost productivity, employee satisfaction, and even help us tackle global challenges.  Read on and unleash the power of kindness in your workplace and your life!.{" "}
                <br />
                <br />
                Explore sustainable kindness and its potential to positively impact business landscapes within an organization. Examine the link between kindness and employee productivity. Read on and discover how to cultivate and lead a kinder, more successful workplace.{" "}
              </div>{" "}
              <div class="row business-case-btn  mx-auto"><a href={SurvivalKindest}>
                <button type="button" class="btn btn-outline-dark">
                  {" "}
                  Download PDF{" "}
                </button>{" "}
                </a>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div class="row"></div>
          <div class="newsroom-heading">Media</div>
          <div class="row">
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={FirstImage} alt="media-images" />
              <a
                href="https://www.facebook.com/watch/live/?v=708190700044584"
                class="media-desc-text"
              >
                CNBCTV18 Live panel discussion- Sharing about the it’s Cool to
                be Kind
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={SecondImage} alt="media-images" />
              <a
                href="https://www.magzter.com/stories/Health/Life-Positive/Caring-through-kindness"
                class="media-desc-text"
              >
                Life Positive – Caring through Kindness
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={ThirdImage} alt="media-images" />
              <a
                href="https://lonepack.org/blog/index.php/2019/11/29/lonepack-conversations-nirmala-mehendale-kindness-unlimited/"
                class="media-desc-text"
              >
                LonePack Conversations – Nirmala Mehendale & Kindness Unlimited
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={FourthImage} alt="media-images" />
              <a
                href="https://www.sujatawde.com/2020/11/2000-vikramgad-residents-receive-cups.html?m=1"
                class="media-desc-text"
              >
                Spirit of Mumbai – 2000 VIKRAMGAD RESIDENTS RECEIVE CUPS OF
                KINDNESS!
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={FifthImage} alt="media-images" />
              <a
                href="https://www.hindustantimes.com/cities/volunteer-for-ngo-in-city-get-tickets-to-ankur-tiwari-tanmay-bhat-concert/story-40Y6MSJNugkkXT6oa78XiM.html"
                class="media-desc-text"
              >
                Hindustan Times – Encash your kind deeds for a concert in the
                city{" "}
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={SixthImage} alt="media-images" />
              <a
                href="https://www.dnaindia.com/mumbai/report-carter-road-marks-world-kindness-week-with-a-flash-mob-2033618"
                class="media-desc-text"
              >
                DNA – Carter Road marks World Kindness Week with a flash mob{" "}
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={SeventhImage} alt="media-images" />
              <a
                href="https://thecsrjournal.in/csr-society-tea-vikramgad-families/"
                class="media-desc-text"
              >
                CSR – Society Tea runs tea drive in Vikramgad district{" "}
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={EighthImage} alt="media-images" />
              <a
                href="https://issuu.com/gentletude/docs/kindnessindia_single"
                class="media-desc-text"
              >
                Issuu – Kindness in India. The Colors of Altruism.{" "}
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={NinethImage} alt="media-images" />
              <a
                href="https://m.timesofindia.com/life-style/spotlight/an-initiative-for-the-greater-good/amp_articleshow/79197833.cms"
                class="media-desc-text"
              >
                Times of India – An initiative for the greater good{" "}
              </a>
            </div>
            <div class="col-lg-4 col-md-6 media-boxes">
              <img class="media-images" src={TenthImage} alt="media-images" />
              <a
                href="https://www.linkedin.com/posts/anshuman_kindness-at-the-workplace-a-conversation-activity-6745335025232945152-T9Xu"
                class="media-desc-text"
              >
                LinkedIn – Kindness at the Workplace A Conversation Activity{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsroom;
