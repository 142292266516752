import React from "react";
import "./Partnership.css";
import Saurabh from "../../../images/SaurabhVedwal.jpg";
import Abhipsha from "../../../images/AbhipshaMukherjee.jpg";


const Partnership = () => {
  return (
    <section class="partnership">
      <h3 class="heading-partnership">
        Partnership & Collaboration Opportunities
      </h3>
      <h5 class="sub-heading-partnership">
        To discuss possible opportunities, kindly reach out to us at{" "}
        <b>
          <a href="mailto:contactus@kindnessunlimited.in" class="email">
            contactus@kindnessunlimited.in
          </a>
        </b>
      </h5>
      <h5 class="sub-heading-partnership color-pt">Testimonals</h5>
      <div class="feedback-plt">
        <div class="feedback-person-plt">
          <div class="feedback-text-plt">
            <p>
              Connecting with people and collecting their stories. I enjoyed
              listening their stories and sharing my own observations.
            </p>
          </div>
          <div class="feedback-person-details-plt">
            <img  class="testimonial-img" src={Saurabh} alt="person-1" />
            <div class="user-details-plt">
              <div class="user-name-plt">Saurabh Kumar</div>
              <div class="user-place-plt">Volunteer, 1MillionStory Campaign</div>
            </div>
          </div>
        </div>

        <div class="feedback-person-plt">
          <div class="feedback-text-plt">
            <p>
              It again bridged the gap which was created due to lack of
              communication or interaction with my family and relatives and long
              lost friends and old buddies. This movement made me realise that
              goodness still exists as i came across the stories and acts of my
              assisters. It gives me hope that world can still be a better place
              to live in.
            </p>
          </div>
          <div class="feedback-person-details-plt">
            <img  class="testimonial-img"  src={Abhipsha} alt="person-1" />
            <div class="user-details-plt">
              <div class="user-name-vlt">Abhipsha Mukherjee</div>
              <div class="user-place-plt">Volunteer, 1MillionStory Campaign</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partnership;
