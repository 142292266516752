import React, { useEffect }  from "react";
import "./KindnessMusic.css";
import nspa1 from "../../../images/nspa1.jpg";
import nspa2 from "../../../images/nspa2.jpg";
import nspa3 from "../../../images/nspa3.jpg";
import nspa4 from "../../../images/nspa4.jpg";

const KindnessMusic = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="nspa-music">
      <div class="col">
        <div class="content-a cont-head" style={{ paddingLeft: "19px" }}>
        Kindness in Music – NSPA
        </div>
        <p class="text">
          There’s no better way to tell the kindness story than through song, so
          in the debut year of the Equal Streets Initiative, Kindness Unlimited
          collaborated with the National Streets of Performing Arts, a NPO
          dedicated to bringing culture back to the streets, whose artists not
          only filled the air with moving renditions, but also got us all to
          celebrate the joy of being good!
        </p>
        <div
          class="carousel slide"
          data-bs-ride="carousel"
          id="carouselExampleCaptions"
        >
          <div class="carousel-inner">
            <div class="carousel-item active bg-1">
              <div class="img-container-carousel d-flex">
                <img class="img-main" src={nspa1} />
              </div>
            </div>
            <div class="carousel-item bg-2">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={nspa2} />
              </div>
            </div>
            <div class="carousel-item bg-3">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={nspa3} />
              </div>
            </div>
            <div class="carousel-item bg-4">
              <div class="img-container-carousel d-flex">
                <img class="img-main img-fluid" src={nspa4} />
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            data-bs-slide="prev"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <span aria-hidden="true" class="carousel-control-prev-icon"></span>{" "}
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            data-bs-slide="next"
            data-bs-target="#carouselExampleCaptions"
            type="button"
          >
            <span aria-hidden="true" class="carousel-control-next-icon"></span>{" "}
            <span class="visually-hidden">Next</span>
          </button>

          <div class="carousel-indicators">
            <button
              aria-label="Slide 1"
              class="active"
              data-bs-slide-to="0"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={nspa1} />
            </button>{" "}
            <button
              aria-label="Slide 2"
              data-bs-slide-to="1"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={nspa2} />
            </button>{" "}
            <button
              aria-label="Slide 3"
              data-bs-slide-to="2"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={nspa3} />
            </button>
            <button
              aria-label="Slide 4"
              data-bs-slide-to="3"
              data-bs-target="#carouselExampleCaptions"
              type="button"
            >
              <img class="img-thumb img-fluid" src={nspa4} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KindnessMusic;
