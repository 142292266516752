import React, { useEffect } from "react";
import "./WKW.css";
import Image6 from "../../../images/Image6.png";
import KUJam from "../../../images/KindnessJam.jpg";
import CupKindness from "../../../images/Image4.jpeg";
import Carnival from "../../../images/KUCarnival.jpg";
import Beats from "../../../images/BeatsKU.jpg";
import Dance from "../../../images/danceKU.jpg";

import { Link } from "react-router-dom";

const WKW = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="kindness-week">
        <div class="wkw-heading">World Kindness Week</div>
        <div class="center-part">
          <div class="notes-a">
            <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>2020:</p>
          </div>
          <section class="accommodation">
            <div class="stay">
              <Link to="././cupofkindness" class="stay-content">
                <img src={CupKindness} alt="Image2" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Cup Of Kindness{" "}
                  </div>
                </div>
              </Link>

              <Link to="././carnival" class="stay-content">
                <img src={Carnival} alt="hwgh" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Kindness Carnival{" "}
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>

        <div class="center-part">
          <div class="notes-a">
            <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>2019:</p>
          </div>
          <section class="accommodation">
            <div class="stay">
              <Link to="././kindnessjam" class="stay-content">
                <img src={KUJam} alt="Image4" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    The Kindness Jam{" "}
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>
        <div class="center-part">
          <div class="notes-a">
            <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>2018:</p>
          </div>
          <section class="accommodation">
            <div class="stay">
              <Link to="././fooddistribution" class="stay-content">
                <img src={Image6} alt="hwgh" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Food Distribution
                  </div>
                </div>
              </Link>
              </div>
          </section>
        </div>
        <div class="center-part">
          <div class="notes-a">
            <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Other events for Week of Kindness:
            </p>
          </div>
          <section class="accommodation">
            <div class="stay">
              <Link to="././beats" class="stay-content">
                <img src={Beats} alt="hwgh" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Beats of a Kind{" "}
                  </div>
                </div>
              </Link>
              <Link to="././dancekindness" class="stay-content">
                <img src={Dance} alt="hwgh" />

                <div class="stay-details">
                  <div class="stay-details-name" style={{ fontSize: "1.3rem" }}>
                    Dance for Kindness{" "}
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default WKW;
