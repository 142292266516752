import React, { useEffect } from "react";
import "./PlantKindness.css";
import Sambe from "../../../images/Sambe.jpg";
import { Link } from "react-router-dom";


const PlantKindness = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="plant-kindness">
    <div class="about-1">
      <div class="content-a cont-head">Project Plant Kindness 2.0</div>
      <div class="about-us-contents-a">
        <div class="about-us-contents-left-a">
          <img src={Sambe} alt="hwgh" />
        </div>

        <div class="about-us-contents-right-a">
          <div class="notes-a notes-a-p">
            <p>
              Celebrating 75 glorious years of independent India, KU partnered
              with the MAD Foundation & the Snehavardhini Social Trust, to plant
              1,00,000 fruit trees in 41 Indian villages.
            </p>
            <div
              class="content-a cont-head"
              style={{ paddingBottom: "10px", lineHeight: "38px" }}
            >
              1,00,000 fruit trees  - 41 villages  - 3790+ households with a ‘fruitful future’.
            </div>

            <p>
              The Sustainable Green Initiative provided us with the plants &
              ensured safe & timely delivery of the same. A team of 100+ ‘kind’
              volunteers, from the Palghar area of Mumbai, participated in this
              drive of kindness with KU
            </p>
            <div class="content-a cont-head"> The USP?</div>

            <p>
              Our volunteers didn’t till the soil and plant the seeds for the
              villagers! Instead, they provided the raw material & guided the
              villagers in planting the fruit seeds by themselves.
            </p>
            <p>
              This novel approach helped the villagers:
              <br />
              Develop a sustainable skillset,
              <br />
              Gain self-confidence,
              <br />
              Consume nutritious fruits, <br /> Earn additional source of
              long-term income & <br />
              Gift their future generations a healthier & greener place to live
              in.
            </p>
            <p>
              Indeed a wonderful approach to ‘balancing self-interest with
              greater good’!
            </p>
<Link to="/donate">
            <button class="button donatehome-btn ">DONATE</button></Link>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PlantKindness;
